<template>
    <div class=""><EmpSidebarVue/>
    <div class="intl">
    
    
        <button @click="goBack" class="back mt-5"><v-icon>mdi-arrow-left-bold</v-icon></button>
        <v-data-table
          :headers="headers"
          :items="partialPayments"
          
          class="elevation-1 mt-5"
        >
        
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Interested clients</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                
                <v-card style="width:50rem;height:30rem">
                  <v-card-title>
                    <span class="text-h5">payment Form</span>
                  </v-card-title>
      
                  <v-card-text>
                    <v-container>
                 
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-checkbox v-model="special" label="Special Client"></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-model="date" label="todays Date" type="date"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select v-model="paymentype" :items="paymentoption" label="Select Payment Options" required></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-if="paymentype === 'Full'" v-model="selecteditem[4]" label="Total amount"></v-text-field>
                            <v-text-field v-if="paymentype === 'Partial'" v-model="partialamount" label="Parital amount"></v-text-field>
                            <v-text-field v-if="paymentype === 'Future'" v-model="fdate" label="Future Date" type="date"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <!-- <v-text-field v-if="paymentype !== 'Future'" v-model="selecteditem[9]" label="Amount received by"></v-text-field> -->
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field v-if="paymentype !== 'Future'" v-model="Asendto" label="Amount send to"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select v-model="updatedstatus" :items="items" label="Update Status" required></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select v-model="payment" :items="pstatus" label="Select payment Status" required></v-select>
                          </v-col>
                        </v-row>
                 
                      
                    </v-container>
                  </v-card-text>
      
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue-darken-1"
                      variant="text"
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue-darken-1"
                      variant="text"
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
             
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              size="small"
              class="me-2"
              
              @click="editItem(item.raw)"
            >
              Payment
            </v-btn>
          
          </template>
        
        </v-data-table></div>
        </div>
  </template>
  <script>
  import axios from 'axios'
import EmpSidebarVue from './EmpSidebar.vue'

    export default {
      name:'InterestedLeads',
      
      components:
      {
EmpSidebarVue
      },
      data: () => ({
        dialog:false,
        dialogDelete: false,
        special: false,
        partialPayments:[],
      
        date:'',
        fdate:'',
        updatedstatus:'',
        Asendto:'',
        employeeName :'',
        partialamount:'',
        payment:null,
      paymentype:'',
        paymentoption:['Full','Partial','Future'],
        pstatus:['completed','partiallycompleted','Future'],
          headers: [
          {
            title: 'lead id',
            align: 'start',
            sortable: false,
            key: '0',
          },
          { title: 'clientname', key: '4' },
          { title: 'client_type', key: '12' },
          { title: 'Language', key: '13' },
          { title: 'date', key: '3' },
          { title: 'amount', key: '5' },
          { title: 'Paid', key: '6' },
          { title: 'Remaining', key: '10' },
          { title: 'status', key: '11' },
          { title: 'Actions', key: 'actions', sortable: false },
        ],
       
        // desserts: [],
       
        editedIndex: -1,
        editedItem: {
          0: '',
          client: 0,
          date: 0,
          amount: 0,
          status: 0,
        },
        defaultItem: {
        0: '',
          client: 0,
          date: 0,
          amount: 0,
          status: 0,
        },
        items: [
      "completed","Partial","Future"
    ],
      }),
  
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
       
      },
  
      created () {
        
        this.employeeName = this.$route.params.employeeName;
        this.fetchPaymentClients ()
        // this.interestedlead()
      },
    
      methods: {
        fetchPaymentClients () {
        axios.get('http://127.0.0.1:5000/intcl/payments?employeeName='+ this.employeeName)
      .then(response => {
        const clients = response.data.clients;
        const partialClients = clients.filter(client => client[9] === 'Partial');
        console.log(partialClients);
        this.partialPayments = partialClients;
      });
      },
//         interestedlead()
//       {
//         var empname = this.employeeName
//         const status='interested';
//   axios.post('http://127.0.0.1:5000/leads/status',
//   {name:empname,
//   status:status})
//     .then(response => {
//       this.leads=response.data.leads;
//       console.log(this.leads)
     
//     });
//       },
  
        goBack() {
      this.$router.go(-1)
    },
      
        editItem (item) {
          
          this.editedIndex = this.partialPayments.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dialog = true
          this.selecteditem=item
          console.log(this.selecteditem[1])
        },
  
        
  
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        
  
    //     save () {
    //       axios.post('http://127.0.0.1:5000/intcl/payments', {
    //         emp_name:this.employeeName,

    //     date:this.date,
    //     fdate:this.fdate,
    //     special: this.special ? 'special' : 'regular',
    //     client_name:this.selecteditem[5],
    //     amount:this.selecteditem[4],
    //     partialamount:this.partialamount,
    //     amount_sentto:this.Asendto,
    //     number:this.selecteditem[2],
    //     amount_receivedby:this.selecteditem[9],
    //     paytype:this.paymentype,
    //     // paystatus:this.payment,
    //     language:this.selecteditem[6],
    //     updatedstatus:this.updatedstatus,
    //     branch:this.selecteditem[7]

        
    //   })
    //     .then(response => {
    //      console.log(response.data)});
    //      this.dialogVisible = false;
    //     //  var date=this.date
    //     //  var updatedstatus=this.updatedstatus
    //     // var Asendto=this.Asendto
    //     // var partialamount=this.partialamount
    //     // var payment=this.payment
    //     // var paymentype=this.paymentype
    //     // console.log(paymentype)

   

        
    //     },
      },
    }
  </script> 
  <style scoped>
  .intl
  {

    margin-left: 3rem;
    width: 80rem;
  }
  .back
{
  background:white;
      color:black;
      font-size: 1rem;
      border-radius: 30px;
      height: 3rem;
      width: 3rem;
      box-shadow: 3px 3px 3px 3px #9999 ;
}
  </style>
  
   
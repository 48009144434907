<template>

  <v-container>
    <VRow>
      <v-col cols="12">
        <!-- <v-card class="mx-auto top mt-5" width="300">
          <div class="arr">
            <div class="top">
              <v-icon
                class="ma-2"
                variant="text"
                icon="mdi-login"
                color="pink"
              ></v-icon>
              <v-card-text> Office Login/Logout Time </v-card-text>
            </div>
            <v-card-text v-model="displaytime">
              You are not logged in, Please click on Login
            </v-card-text>
            <v-btn color="#def1fe" @click="login = !login">Login</v-btn>
            
          </div>
        </v-card> -->
      </v-col>
      <!-- <v-col cols="6" style="max-height: 16rem">
        <v-title style="font-size: 1.7rem">Top Employee Data </v-title>
        <v-table class="table">
          <thead style="background-color: #def1fe">
            <tr>
              <th class="text-left">Position</th>
              <th class="text-left">Emp Name</th>
              <th class="text-left">Leads</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in desserts" :key="item.name">
              <td>{{ item.no }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.leads }}</td>
            </tr>
          </tbody>
        </v-table>

       
      </v-col> -->
    </VRow>
    <vRow class="second mt-10">
      <v-col cols="12" sm="4">
        <v-card class="mx-auto top" width="270">
          <div>
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-checkbox-marked"
              color="blue-lighten-2"
            ></v-icon>
            <v-card-text> Assigned Leads </v-card-text>
            <v-card-text>{{ assignedcount }} </v-card-text>
          </div>
          <div>
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="12"
              :model-value="value"
              color="teal"
            >
              {{ value }}
            </v-progress-circular>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="mx-auto top" width="270">
          <div>
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-thumb-up"
              color="green"
            ></v-icon>
            <v-card-text> Completed Leads </v-card-text>
            <v-card-text> {{ completedlead  }} </v-card-text>
          </div>
          <div>
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="12"
              :model-value="value"
              color="teal"
            >
              {{ value }}
            </v-progress-circular>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="mx-auto top" width="270">
          <div>
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-thumb-down"
              color="red-lighten-2"
            ></v-icon>
            <v-card-text> Remaining Leads </v-card-text>
            <v-card-text> {{assignedcount- completedlead  }} </v-card-text>
          </div>
          <div>
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="12"
              :model-value="value"
              color="teal"
            >
              {{ value }}
            </v-progress-circular>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="mx-auto top" width="270">
          <div>
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-sale"
              color="orange"
            ></v-icon>
            <v-card-text> Today Sales </v-card-text>
            <v-card-text> 100 </v-card-text>
          </div>
          <div>
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="12"
              :model-value="value"
              color="teal"
            >
              {{ value }}
            </v-progress-circular>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="mx-auto top" width="270">
          <div>
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-cash"
              color="pink"
            ></v-icon>
            <v-card-text> Monthly Target </v-card-text>
            <v-card-text>Amount- ${{ month[0] }} <br>

              Leads-  {{ month[1] }}</v-card-text>
          </div>
          <div>
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="12"
              :model-value="value"
              color="teal"
            >
              {{ value }}
            </v-progress-circular>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="mx-auto top" width="270">
          <div>
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-calendar-month"
              color="blue-lighten-2"
            ></v-icon>
            <v-card-text> daily Target </v-card-text>
            <v-card-text>Amount- ${{ daily[0] }} <br>

            Leads-  {{ daily[1] }}
               </v-card-text>
          </div>
          <div>
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="12"
              :model-value="value"
              color="teal"
            >
              {{ value }}
            </v-progress-circular>
          </div>
        </v-card>
      </v-col>
    </vRow>
    <VDialog v-model="openAssignpage">
      <VCard>
        <VCardTitle>Assign Leads</VCardTitle>
        <VCardText>
          <VForm width="450">
            <v-select
              v-model="select3"
              :items="items"
              label="Employee"
              required
            ></v-select>
            <VTextField label="No.of leads" v-model="newBranchName" />
          </VForm>
        </VCardText>

        <VCardActions>
          <VBtn text @click="addBranchModal = false">Cancel</VBtn>
          <VBtn text @click="addBranch">Add</VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      employeeName:'',
      openAssignpage: false,
      select3: null,
      month:[],
      daily:[],
      interval: {},
      value: 40,
      assignedcount:'0',
      
      completedlead:'0',
      notinterestedlead:'',
      dndlead:'',
      interested:'interested',
      notinterested:'notinterested',
      completed:'completed',
      dnd:'dnd',
      // status:'interested',
      //
      items: ["Spandana", "John", "Bean", "George", "Honey"],
      desserts: [
        {
          no: 1,
          name: "Frozen",
          leads: 59,
        },
        { no: 2, name: "sandwich", leads: 35 },
        { no: 3, name: "Eclair", leads: 30 },
        { no: 4, name: "Candy", leads: 26 },
        { no: 5, name: "Gingeria", leads: 20 },
      ],
    };
  },
  created() {
    this.employeeName = this.$route.params.employeeName;
    this.completedleads()
    //   this.interestedleads()
    //   this.notinterestedleads()
    //   this.dndleads()
      this.assigncount()
      this.fetchtarget()
     
    },
  methods:
  {
    fetchtarget()
      {
     
  axios.get('http://127.0.0.1:5000/emp/target',
  {
    params: {
      empname: this.employeeName,
    },
    
  })
  .then(response => {
    this.month = response.data.monthly;
    console.log(this.month)
    this.daily=response.data.daily;
    // this.target = response.data.daily;
    // this.employeesList = this.employees.map(emp => emp.name); // get only the names of employees
    console.log( );
  })
  .catch(error => {
    console.error(error);
  });

      },
    completedleads()
    {axios.get('http://127.0.0.1:5000/assigned_leads_count' ,
    {
      params: {
      
      emp_name:this.employeeName
    }
    })
  .then(response => {
    this.completedlead = response.data.count;
    console.log(this.completedlead);
  })
  .catch(error => {
    console.log(error);
  });
},
    
    assigncount() {
  axios.get('http://127.0.0.1:5000/assignesleads', {
    params: {
      
      emp_name:this.employeeName
    }
  })
  .then(response => {
    this.assignedcount = response.data.count[0];
    console.log(this.assignedcount)
  })
  .catch(error => {
    console.log(error);
  });
},
    
    // {
    //     try {
    //       const response = await axios.get('http://127.0.0.1:5000/leads_status');
    //       console.log(response.count);
         
         
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   },
  //   interestedleads() {
  //       axios.post('http://127.0.0.1:5000/leads_status', {
  //         status:this.interested
         
  
         
  //       })
  //         .then(response => {
  //           this.interested=response.data.count[0];
  //           // console.log(this.interested)
  //          });
  //         },
  //     notinterestedleads() {
       
  //       axios.post('http://127.0.0.1:5000/leads_status', {
  //         status:this.notinterested,
         
  
         
  //       })
  //         .then(response => {
  //           this.notinterestedlead=response.data.count[0];
  //           // console.log(this.notinterested)
  //          });
  //         },
  // dndleads() {
       
  //      axios.post('http://127.0.0.1:5000/leads_status', {
  //        status:this.dnd,
        
 
        
  //      })
  //        .then(response => {
  //          this.dndlead=response.data.count[0];
  //         //  console.log(this.intereste)
  //         });
  //        },
  //      completedleads()
  //        { axios.post('http://127.0.0.1:5000/leads_status', {
  //        status:this.completed,
        
 
        
  //      })
  //        .then(response => {
  //          this.completedlead=response.data.count[0];
  //         //  console.log(this.intereste)
  //         });}
  }
};
</script>

<style scoped>
.tot {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.v-progress-circular {
  margin: 1rem;
  height: 30%;
}
.icn {
  color: rgb(242, 18, 171);
  font-size: 2.5rem;
}
.table {
  background-color: rgb(243, 243, 243);
}
</style>

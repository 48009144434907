<template>
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" md="6" class="mt-5" >
          <v-card>
            <v-card-title class="abc">Target</v-card-title>
            <v-card-text>
              <v-form @submit.prevent="submitForm">
                <v-select v-model="branch" :items="branches" label="Choose Branch" required  ></v-select>
                <v-select v-model="language" :items="languages" label="Choose language" required  ></v-select>
                <v-select v-model="employee" :items="employees" label="Choose Employee" required></v-select>
                <v-select v-model="ttype" :items="targettype" label="Target Type" required></v-select>
                <v-select v-if="ttype=== 'Monthly'" v-model="month" :items="months" label="Month" required></v-select>
               
                <v-text-field v-model="amount" label="Amount"></v-text-field>
                <v-text-field v-model="empcountleads" label="NO of Leads"></v-text-field>
                
                <v-btn color="#def1fe" class="mt-4" block type="submit" @click="assigntoemployee">Assign Target</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
  
     
      </v-row>
    </v-container>
  </template>
  
  <script>
  import axios from 'axios';
    export default {
    data: () => ({
      valid: true,
      language:null,
    branches:[],
    month:'',
    languages:[],
    selectedBranch: null,
    branch:'',
    ttype:'',
    amount:'',
    employee:'',
    employees:[],
    empcountleads:'',
      name: "",
      select: null,
      select1: null,
      select2: null,
      targettype:["Monthly","Daily"],
      items: ["Spandana", "John", "Bean", "George", "Honey"],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      date: null,
      firstName: "",
      firstName2: "",
      rules: [
        (v) => !!v || "Amount is required",
        (v) => /^\d+$/.test(v) || "Amount must be a number",
      ],
    }),
    created()
  {
    // this.assignleads()
    this.branche()
    this.fetchlanguages()
    // this.fetchemployees()
    
  },

  
    methods: {
      assigntoemployee()
    {
   
     
        axios.post('http://127.0.0.1:5000/api/target', {
        branch:this.branch,
        emp:this.employee,
        lang:this.language,
        targettype:this.ttype,
        target:this.empcountleads,
        month:this.month,
        amount:this.amount


})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.log(error);
});
        },

      fetchlanguages()
    {
      
  axios.get('http://127.0.0.1:5000/getlanguages')
  .then(response => {
    this.languages = response.data.language;
    // this.employeesList = this.employees.map(emp => emp.name); // get only the names of employees
    console.log(this.languages);
  })
  .catch(error => {
    console.error(error);
  });

    },
    updateEmployees(newVal) {
  axios.get('http://127.0.0.1:5000/getemployees', {
    params: {
      branch: newVal
    }
  })
  .then(response => {
    this.employees = response.data.employees;
    // this.employeesList = this.employees.map(emp => emp.name); // get only the names of employees
    console.log(this.employees);
  })
  .catch(error => {
    console.error(error);
  });
}
,
      async branche()
    {
      try{
        const { data } = await axios.get("http://127.0.0.1:5000/apiget/branchename")
        // this.managers = data.emp_list
        this.branches=data.branch
        
        // console.log(this.branches)
        }
      
      catch (error) {
          // console.error(error);
        }
    },













      async validate() {
        const { valid } = await this.$refs.form.validate();
  
        if (valid) alert("Form is valid");
      },
      async validate2() {
        const { valid } = await this.$refs.form2.validate();
  
        if (valid) alert("Form is valid");
      },
    },
    watch: {
    branch: function(newVal) {
      this.updateEmployees(newVal);
    },
  },
  };
  </script>
  
  <style scoped>
  .abc {
    text-align: center;
  }
  
  .v-card {
    border-radius: 10px;
    box-shadow: 0 5px 5px 5px #8888;
  }
  </style>
  
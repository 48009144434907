
    <template>
     <nav> 
            <v-navigation-drawer class="navi"
            color="#dcdcdc"
              v-model="drawer"
                         >
              <v-list-item
                prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
                :title="employeeName"
                style="color:black"
                nav
              >
                <!-- <template v-slot:append>
                  <v-btn
                    variant="text"
                    icon="mdi-chevron-left"
                    @click.stop="drawer=!drawer"
                  ></v-btn>
                </template> -->
              </v-list-item>
      
              <v-divider></v-divider>
      
              <v-list density="compact" nav >

                <!-- <router-link :to="'/employee/' +employeeName"><v-list-item style="color: black; font-size: 1.2rem;font-weight:600"  prepend-icon="mdi-view-dashboard" title="Dashboard" value="account" class="mt-5 navitems" color="red "></v-list-item></router-link> -->
                <v-list-item style="color: black; font-size: 1.2rem;font-weight:600" :to="'/emphome/' +employeeName" prepend-icon="mdi-view-dashboard" title="Dashboard" value="account" class="mt-5 navitems" color="red "></v-list-item>

             
                <v-list-item style="color:black ;font-size:1.2rem" :to="'/el/' +employeeName" prepend-icon="mdi-account-multiple" title="Leads" value="leads"  class="mt-5 navitems" color="blue"></v-list-item>
                <v-list-item style="color:black ;font-size:1.2rem" :to="'/emptarget/'  +employeeName" prepend-icon="mdi-account-multiple" title="Target" value="Target"  class="mt-5 navitems" color="blue"></v-list-item>

                <v-list-item style="color:black ;font-size:1.2rem" :to="'/Leadrequest/'  +employeeName" prepend-icon="mdi-calendar-month" title="Request" value="lead request"  class="mt-5 navitems" color="pink"></v-list-item>
                <!-- <v-list-item style="color:black ;font-size:1.2rem" content="4" :to="'/empchat/'  +employeeName" prepend-icon="mdi-comment" title="Chat" value="Chat"  class="mt-5 navitems" color="pink"></v-list-item> -->
                <v-list-item :to="'/empchat/'  +employeeName" class="mt-5 navitems" color="pink">
                  <template #default>
                    <v-list-item-avatar>
                      <v-badge color="red" content="4">
                        <v-icon color="blue" size="20">mdi-comment</v-icon>
                      </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content style="color:black; font-size: 1.2rem">
                      Chat
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-list-item style="color:black ;font-size:1.2rem" :to="'/partial/'  +employeeName" prepend-icon="mdi-account-group" title="Partial payments" value="future payments"  class="mt-5 navitems" color="red"></v-list-item>

                <v-list-item style="color:black ;font-size:1.2rem" :to="'/futurepay/'  +employeeName" prepend-icon="mdi-account-group" title="Future payments" value="future payments"  class="mt-5 navitems" color="red"></v-list-item>
                <v-list-item style="color:black ;font-size:1.2rem" :to="'/Emppay/'  +employeeName" prepend-icon="mdi-account-group" title="Payment clients" value="payment client"  class="mt-5 navitems" color="red"></v-list-item>
                <v-list-item style="color:black ;font-size:1.2rem" :to="'/empspcl/'  +employeeName" prepend-icon="mdi-view-dashboard" title="Special clients" value="account"  class="mt-5 navitems" color="red"></v-list-item>
                
               
              </v-list>
            </v-navigation-drawer>

            <v-toolbar app color="#dcdcdc	" dark>
                <!-- <v-btn icon @click="drawer=!drawer">
                  <v-icon class="menuicon">mdi-menu</v-icon>
              </v-btn> -->
              
          
                <v-toolbar-title>
                  <a href="/">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXJGEE73NmHMgU1bWb3JuA6KCG7GUSZDhcIQ&usqp=CAU" alt="Logo" class="logo-img">
                </a>
                </v-toolbar-title>
          
                <v-spacer></v-spacer>
                
               
                
              
                <v-btn stacked>
                  <v-badge color="red" content="5" @click="openMessageBox">
                    <v-icon color="blue">mdi-bell</v-icon>
                  </v-badge>
                </v-btn>
                
                <v-btn
                 color="#ddddd"
                  variant="tonal"
                >
                  <v-icon color="black" icon="mdi-login"></v-icon>
            
                  <p style="color:black" @click="logout">Logout</p>
                </v-btn>
          
                <!-- Additional toolbar items go here -->
              </v-toolbar>
             
          
     </nav>
      </template>

      <script>
      export default {
        data () {
          return {
         
            employeeName:'',
            drawer:true,
            items: [
              { title: 'Home', icon: 'mdi-home-city' },
              { title: 'My Account', icon: 'mdi-account' },
              { title: 'Users', icon: 'mdi-account-group-outline' },
            ],
         
          }
        },
        created() {
          // 
    this.employeeName = this.$route.params.employeeName;
    console.log(this.employeeName);
  //  this.pass()
  },
        methods:
       {
  
          // pass()
          // {
          //   this.$router.push(`/emplead/${this.employeeName}`);
          // },
          // logout()
          // {
          //   axios.
          // }
        }
      }
    </script>

<style>
.navi{
 /* background:#def1fe;*/
 
}
.logo-img  {
  width: 55px;
  height: 55px;
  border-radius: 50px;
}
.menuicon{
  color: black;
}
.navigation{
  background-color: #250361;
}
.navi {
  color: white;


}
.navitems:active
{
  background:lightgreen;

}
.list-item-custom {
  color: black;
  font-size: 1.2rem;
}

.list-item-custom .v-icon {
  color: #3f51b5;
}
</style>


<template>
  <v-container>
    <v-row justify="center" class="lf">
      <v-col cols="12" md="6" lg="4">
        <v-card class="login">
          <v-card-title class="text-center">Login Form</v-card-title>
          <v-card-text>
            <v-form ref="loginForm" @submit.prevent="login">
              <v-text-field v-model="name" label="Username"></v-text-field>
              <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'" label="Password" @click:append="showPassword = !showPassword">
              </v-text-field>
              <v-btn type="submit" color="primary" block>Login</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      name: '',
      password: '',
      showPassword: false
    }
  },
  methods: {
    async login() {
  // handle login logic here
  console.log(`Username: ${this.name}, Password: ${this.password}`);
  try {
    const response = await axios.post('http://127.0.0.1:5000/api/login', {
      name: this.name,
      password: this.password
    })
    if (response.data.status === 'success') {
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('userRole', response.data.role);
      localStorage.setItem('userStatus', response.data.status);
      localStorage.setItem('token', response.data.token);
      if (response.data.role === 'admin') {
        this.$router.push('/AdHome')
      } 
      // else if (response.data.role === 'manager') {
      //   this.$router.push('/MgHome')
      else if (response.data.role === 'manager') {
        const branchName = response.data.branch;
        const manager = response.data.manager;
       
        // alert(branchName)
        // this.$router.push(`/MgHome/${branchName}`);
        this.$router.push({ name: 'mghome', params: { branchName, manager }});

      }
      else if (response.data.role === 'emp') {
  // const branchName = response.data.branch;
  const employeeName = response.data.emp_name;
 
  // this.$router.push({ name: 'emphome', params: { branchName, employeeName }});
  this.$router.push(`/emphome/${employeeName}`);
}

      await Swal.fire({
        icon: 'success',
        title: 'Login Successful',
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Invalid credentials',
        showConfirmButton: false,
        timer: 1500
      })
    }
  } catch (error) {
    console.error(error)
    await Swal.fire({
      icon: 'error',
      title: 'Login Failed',
      text: error.message,
      showConfirmButton: true,
      confirmButtonText: 'OK'
    })
  }
}
// async login() {
//   console.log(`Username: ${this.name}, Password: ${this.password}`);
//   try {
//     const response = await axios.post('http://127.0.0.1:5000/api/login', {
//       name: this.name,
//       password: this.password
//     })
//     if (response.data.status === 'success') {
//       localStorage.setItem('isLoggedIn', true);
//       localStorage.setItem('userRole', response.data.role);
//       localStorage.setItem('userStatus', response.data.status);
//       localStorage.setItem('token', response.data.token);
//       if (response.data.role === 'admin') {
//         this.$router.push('/AdHome')
//       } 
//       else if (response.data.role === 'manager') {
//         const branchName = response.data.branch;
//         this.$router.push(`/MgHome/${branchName}`);
//       }
//       else if (response.data.role === 'emp') {
//         const branchName = response.data.branch;
//         const employeeName = response.data.emp_name;
//         const status = response.data.request_status;
//         if (status === 'pending') {
//           await Swal.fire({
//             icon: 'info',
//             title: 'Request sent',
//             text: 'Your request has been sent to your manager for approval. Please wait for their response.',
//             showConfirmButton: true,
//             confirmButtonText: 'OK'
//           });
//         } else {
//           this.$router.push({ name: 'emphome', params: { branchName, employeeName }});
//         }
//       }

//       await Swal.fire({
//         icon: 'success',
//         title: 'Login Successful',
//         showConfirmButton: false,
//         timer: 1500
//       })
//     } else {
//       await Swal.fire({
//         icon: 'error',
//         title: 'Invalid credentials',
//         showConfirmButton: false,
//         timer: 1500
//       })
//     }
//   } catch (error) {
//     console.error(error)
//     await Swal.fire({
//       icon: 'error',
//       title: 'Login Failed',
//       text: error.message,
//       showConfirmButton: true,
//       confirmButtonText: 'OK'
//     })
//   }
// }


  }
}
</script>

<style>
.lf {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login {
  background: rgba(255, 255, 255, 0.25);
  border: 4px solid rgba(255, 255, 255, 0.25);
  background: blur(20px);
}
</style>

<template>
  <nav>
    <v-navigation-drawer class="navi" v-model="drawer">
      <v-list-item
        prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
        :title="managername"
        style="color: black"
        nav
      >
        <!-- <template v-slot:append>
          <v-btn
            variant="text"
            icon="mdi-chevron-left"
            @click.stop="drawer = !drawer"
          ></v-btn>
        </template> -->
      </v-list-item>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
          style="color: black"
          :to="'/MgHome/' + branchname + '/' + managername"
          prepend-icon="mdi-view-dashboard"
          title="Dashboard"
          value="account"
          class="mt-2"
        ></v-list-item>
        <!-- <v-list-item
          style="color: black"
          to="/RequestLead"
          prepend-icon="mdi-view-dashboard"
          title="Request Lead"
          value="account"
          class="mt-2"
        ></v-list-item> -->
        <v-list-item
          style="color: black"
          :to="'/Target/'+ branchname + '/' + managername"
          prepend-icon="mdi-calendar-month"
          title="targets"
          value="Monthly Sales"
          class="mt-2"
        ></v-list-item>
        <v-list-item
          style="color: black"
          :to="'/ML/'+ branchname + '/' + managername"
          prepend-icon="mdi-account-multiple"
          title="Leads"
          value="Leads"
          class="mt-2"
        ></v-list-item>
        <v-list-item
          style="color: black"
          :to="'/mngemp/'+ branchname + '/' + managername"
          prepend-icon="mdi-account-group"
          title="Employees"
          value="Employees"
          class="mt-3"
        ></v-list-item>
        <v-list-item
          style="color: black"
          :to="'/Payment/'+ branchname + '/' + managername"
          prepend-icon="mdi-credit-card"
          title="Payment Clients"
          value="Payment Clients"
          class="mt-2"
        ></v-list-item>
        <v-list-item
          style="color: black"
          :to="'/splc/'+ branchname + '/' + managername"
          prepend-icon="mdi-home-city"
          title="Special Clients"
          value="Special Clients"
          class="mt-2"
        ></v-list-item>
        <v-list-item
          style="color: black"
          to="/MgSet"
          prepend-icon="mdi-wrench"
          title="Settings"
          value="Settings"
          class="mt-2"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar app color="#def1fe" dark>
      <!-- <v-btn icon @click="drawer = !drawer">
        <v-icon class="menuicon">mdi-menu</v-icon>
      </v-btn> -->

      <v-toolbar-title>
        <a href="/">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXJGEE73NmHMgU1bWb3JuA6KCG7GUSZDhcIQ&usqp=CAU"
            alt="Logo"
            class="logo-img"
          />
        </a>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn stacked>
        <v-badge color="red" content="5">
          <v-icon color="black">mdi-bell</v-icon>
        </v-badge>
      </v-btn>
      <v-btn color="#ddddd" variant="tonal">
        <v-icon color="black" icon="mdi-login"></v-icon>

        <p style="color: black">Logout</p>
      </v-btn>

      <!-- Additional toolbar items go here -->
    </v-toolbar>
  </nav>
</template>

<script>
export default {
  name: "ManagerSidebar",
  data() {
    return {
      drawer: true,
      branchname:'',
      managername:''


    };
  },
  created() {
 this.branchname= this.$route.params.branchName;
 this.managername= this.$route.params.manager;

},
  
};
</script>

<style>
.navi {
  background: #def1fe;
}
.logo-img {
  width: 55px;
  height: 55px;
  border-radius: 50px;
}
.menuicon {
  color: black;
}
.navigation {
  background-color: #def1fe;
}
.navi {
  color: white;
}
</style>

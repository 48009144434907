<template>
  <div class="">
    <AdminSidebarVue/>
    <AdminSplclVue/>

  </div>
</template>

<script>
import AdminSidebarVue from '../../components/Admin/AdminSidebar.vue'
import AdminSplclVue from '../../components/Admin/AdminSplcl.vue'

export default {
    components:
    {
AdminSidebarVue,AdminSplclVue
    }

}
</script>

<style>

</style>
<template>
  <div class=""> 
    <AdminSidebarVue/>
    <v-container>
      <v-card-title class="abc">Assign Leads</v-card-title>
  
      <v-row>
        <v-col cols="12" sm="4">
          <v-card class="mx-auto h" width="280">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-checkbox-marked"
              color="blue"
            ></v-icon>
            <v-card-text>Uploaded Leads </v-card-text>
            <v-card-text class="t"> 30 </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card class="mx-auto h" width="280">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-thumb-up"
              color="green"
            ></v-icon>
            <v-card-text>Assigned Leads </v-card-text>
            <v-card-text class="t"> 10 </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card class="mx-auto h" width="280">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-thumb-left"
              color="red"
            ></v-icon>
            <v-card-text>Remaining Leads </v-card-text>
            <v-card-text class="t"> 20 </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="sample mt-15">
        <!-- <v-col cols="6">
          <v-sheet width="350" class="mx-auto">
            <v-card>
              <v-card-title>Assign Leads To a Branch</v-card-title>
              <v-card-text>
                <v-form>
                 
                  <v-text-field label="no  of leads" v-model="noofleads" />
                  <v-select
                    label="branches"
                    v-model="selectedBranch"
                    :items="branches"
                    item-text="name"
                    item-value="id"
                  />
                </v-form>
              </v-card-text>
      
              <v-card-actions>
                <v-btn text @click="addBranchModal = false">Cancel</v-btn>
                <v-btn text @click="assignleads">Add</v-btn>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-col> -->
        <v-col cols="6">
          <v-sheet width="400" class="mx-auto">
            <v-card>
              <v-card-title>Assign Leads To an Employee</v-card-title>
              <v-card-text>
            <v-form @submit.prevent="submitForm">
              <v-select v-model="branch" :items="branches" label="Choose Branch" required  ></v-select>
              <v-select v-model="language" :items="languages" label="Choose language" required  ></v-select>
              <v-select v-model="employee" :items="employees" label="Choose Employee" required></v-select>
              <v-text-field v-model="empcountleads" label="No. of Leads"></v-text-field>
              
              <v-btn color="#def1fe" class="mt-4" block type="submit" @click="assigntoemployee">Assign</v-btn>
            </v-form>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col cols="6">
          <v-sheet width="350" class="mx-auto">
            <v-card>
              <v-card-title>Remaining Leads</v-card-title>
              <v-card-text>
                
                  <v-table class="table " >
                    <thead style="background-color: #def1fe">
                      <tr>
                        
                        <th class="text-left">Language</th>
                        <th class="text-left">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        
                        <td>telugu</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        
                        <td>telugu</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        
                        <td>telugu</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        
                        <td>telugu</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        
                        <td>telugu</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        
                        <td>telugu</td>
                        <td>100</td>
                      </tr>
                    </tbody>
                  </v-table>
                
            <!-- <v-row>
              <v-col cols="6">Language</v-col>
              <v-col cols="6">Count</v-col>
            </v-row> -->
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>
       
      </v-row>
    </v-container></div>
 
</template>
<script>
import axios from 'axios';
import AdminSidebarVue from './AdminSidebar.vue';
export default {
  components: {
    AdminSidebarVue
  },
  data: () => ({
    selectedBranch: null,
    noofleads:'',
    language:null,
    branches:[],
    languages:[],
    valid: true,
    name: "",
    empcountleads:'',
    branch:'',
    employee:'',
    employees:[],
    select: null,
    select1: null,
   
  }),
  created()
  {
    // this.assignleads()
    this.branche()
    this.fetchlanguages()
    // this.fetchemployees()
    
  },

  methods: {
    fetchlanguages()
    {
      
  axios.get('http://127.0.0.1:5000/getlanguages')
  .then(response => {
    this.languages = response.data.language;
    // this.employeesList = this.employees.map(emp => emp.name); // get only the names of employees
    console.log(this.languages);
  })
  .catch(error => {
    console.error(error);
  });

    },

    assigntoemployee()
    {
   
     
        axios.post('http://127.0.0.1:5000/api/leads/assign', {
          count:this.empcountleads,
          branch_name: this.branch,
          emp_name:this.employee,
          language:this.language
  

})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.log(error);
});
        },
   

    
  

updateEmployees(newVal) {
  axios.get('http://127.0.0.1:5000/getemployees', {
    params: {
      branch: newVal
    }
  })
  .then(response => {
    this.employees = response.data.employees;
    // this.employeesList = this.employees.map(emp => emp.name); // get only the names of employees
    console.log(this.employees);
  })
  .catch(error => {
    console.error(error);
  });
}

,


 
   async branche()
    {
      try{
        const { data } = await axios.get("http://127.0.0.1:5000/apiget/branchename")
        // this.managers = data.emp_list
        this.branches=data.branch
        
        // console.log(this.branches)
        }
      
      catch (error) {
          // console.error(error);
        }
    },

    assignleads()
        {
          console.log(this.noofleads);
          console.log(this.selectedBranch)
        axios.post('http://127.0.0.1:5000/api/leads/assign', {
          count:this.noofleads,
  branch_name: this.selectedBranch
  

})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.log(error);
});
        },
    async validate() {
      const { valid } = await this.$refs.form.validate();

      if (valid) alert("Form is valid");
    },
  },
  watch: {
    branch: function(newVal) {
      this.updateEmployees(newVal);
    },
  },
};
</script>

<style>
.abc {
  text-align: center;
}
.table {
  margin-top: 5%;
  box-shadow: 1px 1px 35px 1px #def1fe;
}
</style>
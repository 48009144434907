<template>
  <div class="">
    <AdminSidebar/>
    <div class="manager mt-5">
      <div class="Dtitle"><button @click="goBack" class="back mt-5"><v-icon>mdi-arrow-left-bold</v-icon></button>
        <v-text style="font-size:1.5rem;font-weight:400">{{ branchName }}</v-text>
        <button @click="addmanagermodel=!addmanagermodel" class="Bman">Add manager</button>
      
       
      </div>
      <v-spacer></v-spacer>

      <VDialog v-model="addmanagermodel">
        <VCard>
          <VCardTitle>Add Manager</VCardTitle>
          <VCardText>
            <VForm>
              
              <VSelect
                label="employees"
                v-model="selectedemployee"
                :items="employees"
                item-text="name"
                item-value="id"
              />
            </VForm>
          </VCardText>
  
          <VCardActions>
            <VBtn text @click="addBranchModal = false">Cancel</VBtn>
            <VBtn text @click="addmanager">Add</VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    

    </div>
    <v-container-fluid >
      <vRow class="mt-10" style="width:100%">
        <v-col cols="8" style="max-height: 100%">
          <v-row>
            <v-col cols="8" sm="6">
             
            </v-col>
          
          </v-row>
  
          <v-row >
            <v-col cols="12" sm="4">
              <v-card class="mx-auto top" width="250" height="10rem">
                <div>
                  <v-icon
                    class="ma-2"
                    variant="text"
                    icon="mdi-checkbox-marked"
                    color="blue-lighten-2"
                  ></v-icon>
                  <v-card-text> Assigned Leads </v-card-text>
                  <v-card-text> 150 </v-card-text>
                </div>
                <div>
                  <v-progress-circular
                    :rotate="360"
                    :size="60"
                    :width="12"
                    :model-value="value"
                    color="teal"
                  >
                    {{ value }}
                  </v-progress-circular>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="mx-auto top" width="250" >
                <div>
                  <v-icon
                    class="ma-2"
                    variant="text"
                    icon="mdi-thumb-up"
                    color="green"
                  ></v-icon>
                  <v-card-text> Completed Leads </v-card-text>
                  <v-card-text> 50 </v-card-text>
                </div>
                <div>
                  <v-progress-circular
                    :rotate="360"
                    :size="60"
                    :width="12"
                    :model-value="value"
                    color="teal"
                  >
                    {{ value }}
                  </v-progress-circular>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="mx-auto top" width="250">
                <div>
                  <v-icon
                    class="ma-2"
                    variant="text"
                    icon="mdi-thumb-down"
                    color="red-lighten-2"
                  ></v-icon>
                  <v-card-text> Remaining Leads </v-card-text>
                  <v-card-text> 100 </v-card-text>
                </div>
                <div>
                  <v-progress-circular
                    :rotate="360"
                    :size="60"
                    :width="12"
                    :model-value="value"
                    color="teal"
                  >
                    {{ value }}
                  </v-progress-circular>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="mx-auto top" width="250">
                <div>
                  <v-icon
                    class="ma-2"
                    variant="text"
                    icon="mdi-sale"
                    color="orange"
                  ></v-icon>
                  <v-card-text> Today Sales </v-card-text>
                  <v-card-text> 100 </v-card-text>
                </div>
                <div>
                  <v-progress-circular
                    :rotate="360"
                    :size="60"
                    :width="12"
                    :model-value="value"
                    color="teal"
                  >
                    {{ value }}
                  </v-progress-circular>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="mx-auto top" width="250">
                <div>
                  <v-icon
                    class="ma-2"
                    variant="text"
                    icon="mdi-cash"
                    color="pink"
                  ></v-icon>
                  <v-card-text> Monthly Target </v-card-text>
                  <v-card-text> $300000 </v-card-text>
                </div>
                <div>
                  <v-progress-circular
                    :rotate="360"
                    :size="60"
                    :width="12"
                    :model-value="value"
                    color="teal"
                  >
                    {{ value }}
                  </v-progress-circular>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card class="mx-auto top" width="250">
                <div>
                  <v-icon
                    class="ma-2"
                    variant="text"
                    icon="mdi-calendar-month"
                    color="blue-lighten-2"
                  ></v-icon>
                  <v-card-text> daily Target </v-card-text>
                  <v-card-text> $100 </v-card-text>
                </div>
                <div>
                  <v-progress-circular
                    :rotate="360"
                    :size="60"
                    :width="12"
                    :model-value="value"
                    color="teal"
                  >
                    {{ value }}
                  </v-progress-circular>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
  
        <v-col cols="4" style="max-height: 15rem">
          <v-title style="font-size: 1.7rem">Top 5 performers </v-title>
          <v-table class="table " >
            <thead style="background-color: #def1fe">
              <tr>
                <th class="text-left">Position</th>
                <th class="text-left">Emp Name</th>
                <th class="text-left">Leads</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in desserts" :key="item.name">
                <td>{{ item.no }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.leads }}</td>
              </tr>
            </tbody>
          </v-table>
  
        </v-col>
        <v-spacer></v-spacer>
      </vRow>
      <VDialog v-model="openAssignpage">
        <VCard>
          <VCardTitle>Assign Leads</VCardTitle>
          <VCardText>
            <VForm width="450">
              <v-select
                v-model="select3"
                :items="items"
                label="Employee"
                required
              ></v-select>
              <VTextField label="No.of leads" v-model="newBranchName" />
            </VForm>
          </VCardText>
  
          <VCardActions>
            <VBtn text @click="addBranchModal = false">Cancel</VBtn>
            <VBtn text @click="addBranch">Add</VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </v-container-fluid>
  </div>

</template>
<!-- <script>
import AdminSidebar from './AdminSidebar.vue';

import axios from 'axios'
export default {
  name: "BranchesDashboard",
  props: ['branchId'],
  components:
  {
    AdminSidebar
  },
  computed: {
     branchName() {
      // get the branch ID from the route parameters
      const branchId = parseInt(this.$route.params.branchId);
      // find the branch with the matching ID in the list of branches
      const branch = this.branches.find(branch => branch.id === branchId);
      // if the branch was found, return its name; otherwise, return "Branch"
      return branch ? `${branch.name}- ${branch.location} MANAGER:${branch.manager}` : 'Branch';
    }
  
  },
  data() {
    return {
      branches: [],
      value: 50,
      employees:[],
      branchName:'',
     bname:'',
      selectedemployee:null,
      addmanagermodel:false,
      items: ["Spandana", "John", "Bean", "George", "Honey"],
      desserts: [
        {
          no: 1,
          name: "Frozen",
          leads: 59,
        },
        { no: 2, name: "sandwich", leads: 35 },
        { no: 3, name: "Eclair", leads: 30 },
        { no: 4, name: "Candy", leads: 26 },
        { no: 5, name: "Gingeria", leads: 20 },
      ],

    }
  },
  created()
  {
    this.fetchbranches()
     // set the branch name from computed property to data property
    this.fetchEmployees();
  },
  

  methods:
  {
    async fetchEmployees() {
      try {
        this.branchName = this.branchName;
        const { data } = await axios.get(`http://127.0.0.1:5000/getemployees?branch=${encodeURIComponent(this.branchName)}`);
        this.employees = data;
        console.log(this.employees)
      } catch (error) {
        console.error(error);
      }
    },


    fetchbranches() {
      axios
      .get("http://127.0.0.1:5000/apiget/branches")
      .then(response => {
        this.branches = response.data;
        console.log(this.branches);
      })
      .catch(error => {
        console.error(error);
      });
    },
    goBack() {
      this.$router.go(-1)
    },
   
    addmanager() {
      const branchId = parseInt(this.$route.params.branchId);
      axios.post('http://127.0.0.1:5000/addmanager', {
        name: this.selectedemployee,
        branchName: this.branches.find(branch => branch.id === branchId).name

      })
      .then(response => {
        console.log(response.data)
      });
    }
    
  },
 
}
</script> -->

<script>
import AdminSidebar from './AdminSidebar.vue';

import axios from 'axios'
export default {
  name: "BranchesDashboard",
  props: ['branchId'],
  components:
  {
    AdminSidebar
  },
  // computed: {
    
  //   branchName() {
  //     console.log(this.branches)
  //     // get the branch ID from the route parameters
  //     const branchId = parseInt(this.$route.params.branchId);
  //     console.log(branchId)
  //     // find the branch with the matching ID in the list of branches
  //     const branch = this.branches.find(branch => branch.id === branchId);
  //     // if the branch was found, return its name; otherwise, return "Branch"
      
  //     // return branch ? branch.name  : 'Branch';
  //     return branch ? `${branch.name}- ${branch.location} MANAGER:${branch.manager}` : 'Branch';

  //   },
  // },
  watch: {
  branches: {
    handler() {
      const branchId = parseInt(this.$route.params.branchId);
      const branch = this.branches.find(branch => branch.id === branchId);
      this.branchname = branch ? branch.name : 'Branch';
    },
    immediate: true,
  },
},

  // watch: {

  //   branchName(newValue) {
  //     console.log(`branchName changed to ${newValue}`);
  //   },
  // },
  data() {
    return {
      branches: [],
      value: 50,
      employees:[],
      selectedemployee:null,
      addmanagermodel:false,
    branchname:'',
     
  
    
    
    }
  },
  created()
  {
    
    this.fetchbranches(),
this.fetchEmployees()
  },
  methods:
  {
    async fetchEmployees() {
      alert(this.branchName)
      console.log(`branchName is ${this.branchname}`);
},
  // try {
  //   await axios.get("http://127.0.0.1:5000/getemployees");
  //   const branch =this.branchName; // replace with the name of the branch you want to filter by
  //   console.log(branch)
  //   this.employees = data.branches.filter(e => e.branch === branch);
  // } catch (error) {
  //   console.error(error);
  // }
//   axios
//      .get("http://127.0.0.1:5000/getemployees",
//      {
//      branch:this.branchName

//      })
//     .then(response => (
//       this.employees = response.data))
//       console.log(this.employees)
// },
   fetchbranches() {
    axios
     .get("http://127.0.0.1:5000/apiget/branches")
    .then(response => (
      this.branches = response.data))
      console.log(this.branches)
  },
    goBack() {
      this.$router.go(-1)
    },
   
    addmanager() {
      const branchId = parseInt(this.$route.params.branchId);
      axios.post('http://127.0.0.1:5000/addmanager', {
        name: this.selectedemployee,
        branchName: this.branches.find(branch => branch.id === branchId).name
      })
      .then(response => {
        console.log(response.data)
      });
    }
    
  },

  
     
    
   


  }
  

</script>
<style scoped>
.table
{
  margin-right: 5rem;
}
.back
{
  background:white;
      color:black;
      font-size: 1rem;
      border-radius: 30px;
      height: 3rem;
      width: 3rem;
      box-shadow: 3px 3px 3px 3px #9999 ;
}
.Dtitle
{
  display: flex;
  justify-content: space-around;
  align-items:center;
}
.Bman
{
  background:#ffff;
  color:black;
  border-radius: 10px;
  font-size: 1rem;
 
  height: 2.5rem;
  width: 7rem;
  box-shadow: 3px 3px 3px 3px #999;

}
.tot {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height:10rem;
}
.v-progress-circular {
  margin: 1rem;
  height: 30%;
}
.icn {
  color: rgb(242, 18, 171);
  font-size: 2.5rem;
}
</style>


    <template>
     <nav> 
            <v-navigation-drawer class="navi"
            
              v-model="drawer"
                         >
              <v-list-item
                prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
                title="John Leider"
                style="color:black"
                nav
              >
                <!-- <template v-slot:append>
                  <v-btn
                    variant="text"
                    icon="mdi-chevron-left"
                    @click.stop="drawer=!drawer"
                  ></v-btn>
                </template> -->
              </v-list-item>
      
              <v-divider></v-divider>
      
              <v-list density="compact" nav >

                
                <v-list-item style="color: black; font-size: 1.2rem;" to="/AdHome" prepend-icon="mdi-view-dashboard" title="Dashboard" value="account" class="mt-5 navitems" color="red "></v-list-item>

                <!-- <v-list-item style="color:black;font-size:1.5rem" to="/AdHome"  prepend-icon="mdi-view-dashboard red--text" title="Dashboard" value="account"  class="mt-5"></v-list-item> -->
                <v-list-item style="color:black ;font-size:1.2rem" to="/AdTarget" prepend-icon="mdi-calendar-month" title="Target" value="Monthly Sales"  class="mt-5 navitems" color="pink"></v-list-item>
                <v-list-item style="color:black ;font-size:1.2rem" to="/Adleads" prepend-icon="mdi-account-multiple" title="Leads" value="Leads"  class="mt-5 navitems" color="blue"></v-list-item>
                <v-list-item style="color:black ;font-size:1.2rem" to="/AdminEmp " prepend-icon="mdi-contacts" title="Employees" value="Employees"  class="mt-5 navitems" color="red"></v-list-item>
                <v-list-item style="color:black ;font-size:1.2rem" to="/Branch" prepend-icon="mdi-view-dashboard" title="Branches" value="account"  class="mt-5 navitems" color="red"></v-list-item>
                <!-- <v-list-item style="color:black" prepend-icon="mdi-account-multiple-plus" title="Team Colabration" value="Team Colabration"  class="mt-2"></v-list-item> -->
                <v-list-item style="color:black;font-size:1.2rem" to="/AdLeadRequest"  prepend-icon="mdi-account-plus" title="Lead Requests" value="Lead Requests"  class="mt-5 navitems" color="red"></v-list-item>
                <v-list-item style="color:black;font-size:1.2rem"  to="/Adpay"  prepend-icon="mdi-credit-card" title="Payment Clients" value="Payment Clients"  class="mt-5 navitems" color="red"></v-list-item>
                <v-list-item style="color:black;font-size:1.2rem"  to="Adsplc" prepend-icon="mdi-home-city" title="Special Clients" value="Special Clients"  class="mt-5 navitems" color="red"></v-list-item>

          
              </v-list>
            </v-navigation-drawer>

            <v-toolbar app color="#def1fe" dark>
                <!-- <v-btn icon @click="drawer=!drawer">
                  <v-icon class="menuicon">mdi-menu</v-icon>
              </v-btn> -->
              
          
                <v-toolbar-title>
                  <a href="/">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXJGEE73NmHMgU1bWb3JuA6KCG7GUSZDhcIQ&usqp=CAU" alt="Logo" class="logo-img">
                </a>
                </v-toolbar-title>
          
                <v-spacer></v-spacer>
                <v-btn stacked>
                  <v-badge
                  color="red" content="5"
                    >
                    <v-icon color="black">mdi-bell</v-icon>
                  </v-badge>
            
                
                </v-btn>
                <v-btn
                 color="#ddddd"
                  variant="tonal"
                >
                  <v-icon color="black" icon="mdi-login"></v-icon>
            
                  <p style="color:black" @click="logout">Logout</p>
                </v-btn>
          
                <!-- Additional toolbar items go here -->
              </v-toolbar>
     </nav>
      </template>

      <script>
      export default {
        name:'AdminSidebar',
        data () {
          return {
            drawer: true,
            items: [
              { title: 'Home', icon: 'mdi-home-city' },
              { title: 'My Account', icon: 'mdi-account' },
              { title: 'Users', icon: 'mdi-account-group-outline' },
            ],
         
          }
        },
        methods:
        {
          // logout()
          // {
          //   axios.
          // }
        }
      }
    </script>

<style>
.navi{
  background:#78b0d5;
  color: red;
}
.logo-img  {
  width: 55px;
  height: 55px;
  border-radius: 50px;
}
.menuicon{
  color: black;
}
.navigation{
  background-color: #250361;
}
.navi {
  color: white;


}
.navitems:active
{
  background:lightgreen;

}
.list-item-custom {
  color: black;
  font-size: 1.2rem;
}

.list-item-custom .v-icon {
  color: #3f51b5;
}
</style>


<template>
  <v-app class="appbar">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
// background-image: linear-gradient(to right,#ccd9ff,#cce0ff);
</script>
<style scoped>
/*.appbar
{
  
background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTURqMcs5GEqOizmD554HMqrlHzcJr8nWcxPJtYVAJLo2EBV4EHbuNPaPlL31ceA2gnWkA&usqp=CAU') no-repeat;
background-size:cover ;
  

}*/

</style>

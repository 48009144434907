<template>
  <v-card>
    <AdminSidebar />
    <v-card>
     
      <v-card-title>Leads Status</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:header>
            <tr>
              <th v-for="header in headers" :key="header.value">{{ header.title }}</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.employeeName }}</td>
              <td>{{ item.completed }}</td>
              <td>{{ item.remaining }}</td>
              <td>{{ item.assigned }}</td>
              <td>{{ item.branchName }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import axios from "axios";
import AdminSidebar from "./AdminSidebar.vue";

export default {
  components: {
    AdminSidebar,
  },
  data() {
    return {
      search: "",
      headers: [
        { title: "Employee Name", value: "employeeName" },
        { title: "Assigned Leads", value: "assigned" },
        { title: "Completed Leads", value: "completed" },
        { title: "Remaining Leads", value: "remaining" },
        { title: "Branch Name", value: "branchName" },
      ],
      items: [],
    };
  },
  methods: {
    fetchLeadsStatus() {
      axios
        .get("http://127.0.0.1:5000/adleadscount")
        .then((response) => {
          const leads = response.data;
          this.items = leads.map((lead) => ({
            employeeName: lead.employeeName,
            assigned: lead.assigned,
            completed: lead.completed,
            remaining: lead.remaining,
            branchName: lead.branchName,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchLeadsStatus();
  },
};
</script>

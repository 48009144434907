<template>
  <div class="">
    <EmpSidebarVue/>
    <div class="intl"><button @click="goBack" class="back mt-5"><v-icon>mdi-arrow-left-bold</v-icon></button>
      <v-data-table
        :headers="headers"
        :items="leads"
        class="elevation-1 mt-5"
        
        
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Payments clients Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EmpSidebarVue from './EmpSidebar.vue'

export default {
  name: 'InterestedLeads',
  components: {
    EmpSidebarVue
  },
  data: () => ({
employeeName:'',
leads:[],
headers: [
        { title: 'ID', value: '0' },
        { title: 'Employee Name', value: '1' },
      
        { title: 'Date', value: '3' },
        { title: 'Client Name', value: '4' },
        { title: 'Amount', value: '5' },
        { title: 'Received Amount', value: '6' },
        { title: 'Amount Received To', value: '7' },
        { title: 'Amount Sent To', value: '8' },
        { title: 'Payment Type', value: '9' },
        { title: 'Remaining Amount', value: '10' },
        { title: 'Status', value: '11' },
        { title: 'Client type', value: '12' },
        { title: 'Language', value: '13' }
      ]

  }),

  
  created () {
    this.employeeName = this.$route.params.employeeName;
    this.fetchPaymentClients();
  },

  methods: {
    fetchPaymentClients () {
  axios.get('http://127.0.0.1:5000/intcl/payments?employeeName=' + this.employeeName)
    .then(response => {
      this.leads = response.data.clients;
      console.log(this.leads);
    });
},

   

  }}
  </script>

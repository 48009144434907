<template>
  <div class="">
<AdminSidebarVue/>
<AdLeadVue/>
  </div>
</template>

<script>
import AdLeadVue from '../../components/Admin/AdLead.vue'
import AdminSidebarVue from '../../components/Admin/AdminSidebar.vue'

export default {
components:
{
    AdminSidebarVue,AdLeadVue
}
}
</script>

<style>

</style>
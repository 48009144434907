<template>
  <div class="chat-container">
    <v-app-bar app dense color="primary" dark>
      <v-toolbar-title>My Chat App</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>
    <v-divider></v-divider>
    <v-list>
      <v-list-item v-for="message in messages" :key="message.id">
        <v-avatar>
          <img :src="message.avatar" alt="">
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ message.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ message.text }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-text-field v-model="newMessage" label="Type a message" append-icon="mdi-send" @keyup.enter="sendMessage"></v-text-field>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: [
        {
          id: 1,
          name: 'John',
          text: 'Hello',
          avatar: 'https://picsum.photos/50'
        },
        {
          id: 2,
          name: 'Mary',
          text: 'Hi, how are you?',
          avatar: 'https://picsum.photos/50'
        },
        {
          id: 3,
          name: 'John',
          text: 'I\'m good, thanks for asking',
          avatar: 'https://picsum.photos/50'
        }
      ],
      newMessage: ''
    }
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        const message = {
          id: this.messages.length + 1,
          name: 'John',
          text: this.newMessage.trim(),
          avatar: 'https://picsum.photos/50'
        }
        this.messages.push(message)
        this.newMessage = ''
      }
    }
  }
}
</script>

<style>
.chat-container {
  height: 100%;
}
</style>

<template>
  <v-container>
    <vRow>
      <v-col cols="8" style="max-height: 100%">
        <v-row>
          <v-col cols="8" sm="6">
            <!-- <v-card class="mx-auto top" width="230">
              <div>
                <v-card-title> Branch-1 </v-card-title>
                <v-card-title>Employees- 150</v-card-title>
              </div>
              <div>
                <v-icon icon="mdi-account-multiple" class="icn"></v-icon>
              </div>
            </v-card> -->
          </v-col>
          <!-- <v-card
            class="h"
            color="#F2F2F2"
            @click="openAssignpage = !openAssignpage"
            style="margin-top: 2%"
            width="180"
            height="80"
          >
            <v-icon>mdi-upload</v-icon>
            <v-card-item> Assign Leads </v-card-item>
          </v-card> -->
        </v-row>

        <v-row>
          <v-col cols="12" sm="4">
            <v-card class="mx-auto top" width="250">
              <div>
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-checkbox-marked"
                  color="blue-lighten-2"
                ></v-icon>
                <v-card-text> Assigned Leads </v-card-text>
                <v-card-text> {{ alcount }} </v-card-text>
              </div>
              <div>
                <v-progress-circular
                  :rotate="360"
                  :size="60"
                  :width="12"
                  :model-value="value"
                  color="teal"
                >
                  {{ value }}
                </v-progress-circular>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="mx-auto top" width="250">
              <div>
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-thumb-up"
                  color="green"
                ></v-icon>
                <v-card-text> Completed Leads </v-card-text>
                <v-card-text> {{ completedlead }} </v-card-text>
              </div>
              <div>
                <v-progress-circular
                  :rotate="360"
                  :size="60"
                  :width="12"
                  :model-value="value"
                  color="teal"
                >
                  {{ value }}
                </v-progress-circular>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="mx-auto top" width="250">
              <div>
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-thumb-down"
                  color="red-lighten-2"
                ></v-icon>
                <v-card-text> Remaining Leads </v-card-text>
                <v-card-text>{{alcount- completedlead }} </v-card-text>
              </div>
              <div>
                <v-progress-circular
                  :rotate="360"
                  :size="60"
                  :width="12"
                  :model-value="value"
                  color="teal"
                >
                  {{ value }}
                </v-progress-circular>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="mx-auto top" width="250">
              <div>
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-sale"
                  color="orange"
                ></v-icon>
                <v-card-text> Today Sales </v-card-text>
                <v-card-text> 100 </v-card-text>
              </div>
              <div>
                <v-progress-circular
                  :rotate="360"
                  :size="60"
                  :width="12"
                  :model-value="value"
                  color="teal"
                >
                  {{ value }}
                </v-progress-circular>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="mx-auto top" width="250">
              <div>
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-cash"
                  color="pink"
                ></v-icon>
                <v-card-text> Monthly Target </v-card-text>
                <v-card-text> $300000 </v-card-text>
              </div>
              <div>
                <v-progress-circular
                  :rotate="360"
                  :size="60"
                  :width="12"
                  :model-value="value"
                  color="teal"
                >
                  {{ value }}
                </v-progress-circular>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card class="mx-auto top" width="250">
              <div>
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-calendar-month"
                  color="blue-lighten-2"
                ></v-icon>
                <v-card-text> daily Target </v-card-text>
                <v-card-text> {{ branchname }} </v-card-text>
              </div>
              <div>
                <v-progress-circular
                  :rotate="360"
                  :size="60"
                  :width="12"
                  :model-value="value"
                  color="teal"
                >
                  {{ value }}
                </v-progress-circular>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4" style="max-height: 15rem">
        <v-title style="font-size: 1.7rem">Top Employee Data </v-title>
        <v-table class="table">
          <thead style="background-color: #def1fe">
            <tr>
              <th class="text-left">Position</th>
              <th class="text-left">Emp Name</th>
              <th class="text-left">Leads</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in desserts" :key="item.name">
              <td>{{ item.no }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.leads }}</td>
            </tr>
          </tbody>
        </v-table>

        <!-- <v-row> <bar-chart :options="chartOptionsBar"></bar-chart> </v-row> -->
      </v-col>
    </vRow>
    <VDialog v-model="openAssignpage">
      <VCard>
        <VCardTitle>Assign Leads</VCardTitle>
        <VCardText>
          <VForm width="450">
            <v-select
              v-model="select3"
              :items="items"
              label="Employee"
              required
            ></v-select>
            <VTextField label="No.of leads" v-model="newBranchName" />
          </VForm>
        </VCardText>

        <VCardActions>
          <VBtn text @click="addBranchModal = false">Cancel</VBtn>
          <VBtn text @click="addBranch">Add</VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  props: {
  branchName: {
    type: String,
    required: true
  },
  manager: {
    type: String,
    required: true
  }
},


  data() {
    return {
      openAssignpage: false,
      select3: null,
      interval: {},
      alcount:'0',
      completedlead:'0',
      value: 40,
      branchname:'',
      managername :'',
      employees:[],

     
      items: ["Spandana", "John", "Bean", "George", "Honey"],
      desserts: [
        {
          no: 1,
          name: "Frozen",
          leads: 59,
        },
        { no: 2, name: "sandwich", leads: 35 },
        { no: 3, name: "Eclair", leads: 30 },
        { no: 4, name: "Candy", leads: 26 },
        { no: 5, name: "Gingeria", leads: 20 },
      ],
    };
  },
  created() {
 this.branchname= this.$route.params.branchName;
 this.managername= this.$route.params.manager;
 this.assignedleads()
 this.completedleads()


},
 
methods:
{
  assignedleads() {
  axios.get(`http://127.0.0.1:5000/manager/leadsassigned/${this.branchname}`).then(response => {
    this.alcount = response.data.data[0];
    console.log(this.leads);
  });
},
completedleads()
    {axios.get('http://127.0.0.1:5000/assigned_leads_count' ,
    {
      params: {
      branch_name:this.branchname
    }
    })
  .then(response => {
    this.completedlead = response.data.count;
    console.log(this.completedlead);
  })
  .catch(error => {
    console.log(error);
  });

}

},
};
</script>

<style scoped>
.tot {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.v-progress-circular {
  margin: 1rem;
  height: 30%;
}
.icn {
  color: rgb(242, 18, 171);
  font-size: 2.5rem;
}
.table {
  background-color: rgb(243, 243, 243);
}
</style>

<template>
  <EmpSidebar />
  <v-container class="total">
    <v-sheet width="500" class="mx-auto">
      <v-card-text class="txt">New Payment</v-card-text>
      <v-form fast-fail @submit.prevent>
        <v-text-field
          v-model="date"
          label="Select Date"
          type="date"
        ></v-text-field>
        <v-text-field label="Client name"></v-text-field>
        <v-text-field
          mt="5"
          v-model="firstName"
          label="Mobile Number"
          type="number"
        ></v-text-field>
        <v-text-field
          mt="5"
          v-model="altnum"
          label="Alternate Mobile Number"
          type="number"
        ></v-text-field>
        <v-text-field
          mt="5"
          v-model="rpay"
          label="Received Amount"
          type="number"
        ></v-text-field>
        <v-text-field
          mt="5"
          v-model="spay"
          label="Sent Amount"
          type="number"
        ></v-text-field>
        <v-text-field
          mt="5"
          v-model="tpay"
          label="Amount"
          type="number"
        ></v-text-field>
        <v-text-field label="Message "></v-text-field>

        <!-- <v-text-field v-model="firstName" label="Amount"></v-text-field> -->
        <div>
          <v-btn type="submit" color="red" class="mt-2 bt">Submit</v-btn>
          <v-btn type="submit" color="primary" to="/" class="mt-2 bt"
            >Back</v-btn
          >
        </div>
      </v-form>
    </v-sheet>
  </v-container>
</template>
<script>
import EmpSidebar from './EmpSidebar.vue';
export default {
  components: {
    EmpSidebar,
  },
  name: "ReqLead",
  data: () => ({
    firstName: "",
    items: [
      "Intrested",
      "Free Trail",
      "Follow us",
      "Busy/Not pick",
      "Switch off",
      "Wrong number",
      "Special clients",
    ],
    payopt: ["Cash", "Nifty", "Bank Nifty", "Other Options"],
    firstNameRules: [
      (value) => {
        if (value?.length > 1) return true;

        return "Leads must be at least 10 ";
      },
    ],
  }),
};
</script>
<style>
.total {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.mx-auto {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.txt {
  /* height: 2rem; */
  text-align: center;
  font-size: 1.2rem;
  background-color: #def1fe;
}
.bt {
  margin-left: 1rem;
}
</style>

<template>
  <v-container>
    <v-card-title>
      <span class="text-h5">Leads </span>
    </v-card-title>
    <v-row class="tota">
      <v-row class="tot">
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-thumb-up"
              color="green"
            ></v-icon>
            <v-card-text>Answered </v-card-text>
            <v-card-text class="t"> {{ todaycount }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-thumb-down"
              color="red"
            ></v-icon>
            <v-card-text>Not Answered </v-card-text>
            <v-card-text class="t">{{ assigned-todaycount }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-checkbox-marked"
              color="blue"
            ></v-icon>
            <v-card-text>Intrested</v-card-text>
            <v-card-text class="t"> {{ intcount}}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-minus-circle"
              color="red"
            ></v-icon>
            <v-card-text> Not Intrested</v-card-text>
            <v-card-text class="t">{{ notcount }} </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="tot">
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-phone"
              color="blue"
            ></v-icon>
            <v-card-text>Today Calls</v-card-text>
            <v-card-text class="t">{{ todaycount }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-cancel"
              color="pink"
            ></v-icon>
            <v-card-text>DND</v-card-text>
            <v-card-text class="t"> {{ dnd }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-cash"
              color="teal"
            ></v-icon>
            <v-card-text>Sales</v-card-text>
            <v-card-text class="t"> $150 </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-account-group"
              color="orange"
            ></v-icon>
            <v-card-text>Follow Ups</v-card-text>
            <v-card-text class="t"> {{followupcount}}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <div class="table">
      <v-data-table
        :custom-filter="filterOnlyCapsText"
        :headers="headers"
        :items="emp"
        :search="search"
        class="elevation-1"
        item-value="name"
        height="20rem"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search Employee (UPPER CASE ONLY)"
            class="pa-4"
          ></v-text-field>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios';
export default {
  data: () => ({
    search: "",
    employeeName:'',
        intcount:'0',
        notcount:'0',
        dnd:'0',
        assigned:'0',
        busy:'0',
        todaycount:'0',
        followupcount:'0',
        assignedcount :'0',
        
    emp: [
      {
        name: "Frocuer",
        eid: 1,
        ass: 60,
        com: 24,
        protein: 4.0,
        status: "100%",
      },
      {
        name: "Spandana",
        eid: 2,
        ass: 90,
        com: 37,
        status: "18%",
      },
      {
        name: "Elon",
        eid: 3,
        ass: 56,
        com: 23,
        status: "75%",
      },
      {
        name: "Muskcake",
        eid: 4,
        ass: 37,
        com: 67,
        status: "80%",
      },
      {
        name: "George",
        eid: 5,
        ass: 16.0,
        com: 49,
        status: "16%",
      },
      {
        name: "Bean",
        eid: 6,
        ass: 25,
        com: 94,
        status: "10%",
      },
      {
        name: "Dolli",
        eid: 7,
        ass: 80,
        com: 98,
        status: "20%",
      },
      {
        name: "Honey",
        eid: 8,
        ass: 32,
        com: 87,
        status: "45%",
      },
      {
        name: "Donut",
        eid: 9,
        ass: 25,
        com: 51,
        status: "22%",
      },
      {
        name: "Kitty",
        eid: 10,
        ass: 26,
        com: 65,
        status: "6%",
      },
    ],
  }),
  computed: {
    headers() {
      return [
        {
          title: "EmpID",
          align: "end",
          key: "eid",
        },
        {
          title: "Emp Name",
          align: "start",
          sortable: false,
          key: "name",
        },

        { title: "Leads assigned", align: "end", key: "ass" },
        { title: "Completed", align: "end", key: "com" },
        { title: "Status", align: "end", key: "status" },
      ];
    },
  },
  created()
  {
    this.branchname= this.$route.params.branchName;
 this.managername= this.$route.params.manager;
    console.log(this.employeeName);
    this.fetchInterested()
    this.fetchnotInterested()
    this.fetchDnd()
    this.fetchbusy()
    this.fetchtodaycalls()
    this.FollowUp()
    this.assignedleads()
  },
  methods: {
    assignedleads() {
  axios.get(`http://127.0.0.1:5000/manager/leadsassigned/${this.branchname}`).then(response => {
    this.assigned = response.data.data[0];
    console.log(this.leads);
  });
},
    FollowUp() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Follow Up',
      branch:this.branchname

    }
  })
  .then(response => {
    this.followupcount = response.data.count[0];
  })
  .catch(error => {
    console.log(error);
  });
},
    
    fetchtodaycalls()
    {
      axios.get('http://127.0.0.1:5000/todaycalls')
  .then(response => {
    this.todaycount = response.data.count;
    console.log(this.todaycount)
  })
  .catch(error => {
    console.log(error);
  });
      
    },
    fetchInterested() {
  axios.get('http://127.0.0.1:5000//branch/leads_status', {
    params: {
      status: 'Interested',
      branch:this.branchname
    }
  })
  .then(response => {
    this.intcount = response.data.count[0];
  })
  .catch(error => {
    console.log(error);
  });
},
fetchnotInterested() {
  axios.get('http://127.0.0.1:5000//branch/leads_status', {
    params: {
      status: 'Not interested',
      branch:this.branchname
    }
  })
  .then(response => {
    this.notcount = response.data.count[0];
  })
  .catch(error => {
    console.log(error);
  });
},
fetchbusy() {
  axios.get('http://127.0.0.1:5000//branch/leads_status', {
    params: {
      status: 'Busy/Not Answered',
      branch:this.branchname
    }
  })
  .then(response => {
    this.busy = response.data.count[0];
  })
  .catch(error => {
    console.log(error);
  });
},
fetchDnd() {
  axios.get('http://127.0.0.1:5000//branch/leads_status', {
    params: {
      status: 'Dnd',
      branch:this.branchname
    }
  })
  .then(response => {
    this.dnd = response.data.count[0];
  })
  .catch(error => {
    console.log(error);
  });
}

,
    filterOnlyCapsText(value, query) {
      return (
        value != null &&
        query != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(query) !== -1
      );
    },
  },
};
</script>
<style>
.table {
  margin-top: 5%;
  box-shadow: 1px 1px 35px 1px #def1fe;
}
.tota {
  margin-top: 3%;
}

.h {
  text-align: center;
  height: 10rem;
}
.t {
  font-size: 1.6rem;
}
</style>

<template>
  <div class="">
    <v-container>
        <v-row>
            <v-col cols="12" sm="4">
                <!-- <v-card color="#250361" class="cards" @click="openFileInput"> -->
                  <v-card  class="cards" @click="openleadsmodel=!openleadsmodel">
                    <v-icon color="green" >mdi-home</v-icon>
                  <v-card-item>
                      Upload Leads
                      <input type="file" ref="fileInput" @change="uploadFile" hidden/>
                  </v-card-item>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4">
                <v-card  class="cards" to="/assign">
                    <v-icon>mdi-upload</v-icon>
                    <v-card-item>
                        Assign Leads
                    </v-card-item>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4">
                <v-card  class="cards" to="/accounts">
                    <v-icon>mdi-account-plus</v-icon>
                    <v-card-item>
                        Accounts
                    </v-card-item>   
                </v-card>
            </v-col>
        </v-row>

      
       <v-container>
        <v-card-title>
          <span class="text-h5">Leads </span>
        </v-card-title>
        <v-row class="tota">
          <v-row class="tot">
            <v-col cols="12" sm="3">
              <v-card class="mx-auto h" width="250">
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-thumb-up"
                  color="green"
                ></v-icon>
                <v-card-text>Answered </v-card-text>
                <v-card-text class="t"> 0 </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto h" width="250">
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-thumb-down"
                  color="red"
                ></v-icon>
                <v-card-text>Not Answered </v-card-text>
                <v-card-text class="t"> 0 </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto h" width="250" to="/interest">
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-checkbox-marked"
                  color="blue"
                ></v-icon>
                <v-card-text>Intrested</v-card-text>
                <v-card-text class="t"> {{ intcount }} </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto h" width="250">
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-minus-circle"
                  color="red"
                ></v-icon>
                <v-card-text> Not Intrested</v-card-text>
                <v-card-text class="t"> {{ notcount }} </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="tot">
            <v-col cols="12" sm="3">
              <v-card class="mx-auto h" width="250">
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-phone"
                  color="blue"
                ></v-icon>
                <v-card-text>Today Calls</v-card-text>
                <v-card-text class="t"> {{ todaycount }} </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto h" width="250">
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-cancel"
                  color="pink"
                ></v-icon>
                <v-card-text>DND</v-card-text>
                <v-card-text class="t"> {{ dnd }} </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto h" width="250">
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-cash"
                  color="teal"
                ></v-icon>
                <v-card-text>Sales</v-card-text>
                <v-card-text class="t"> 0 </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="mx-auto h" width="250">
                <v-icon
                  class="ma-2"
                  variant="text"
                  icon="mdi-account-group"
                  color="orange"
                ></v-icon>
                <v-card-text>Follow Ups</v-card-text>
                <v-card-text class="t"> {{followupcount}}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        
      </v-container>
        <!-- dialog for assigning leads -->
        <v-dialog v-model="addBranchModal">
          <v-card>
            <v-card-title>Assign Leads To a Branch</v-card-title>
            <v-card-text>
              <v-form>
                <!-- <v-text-field label="Branch Name" v-model="newBranchName" /> -->
                <v-text-field label="no  of leads" v-model="noofleads" />
                <v-select
                  label="branches"
                  v-model="selectedBranch"
                  :items="branches"
                  item-text="name"
                  item-value="id"
                />
              </v-form>
            </v-card-text>
    
            <v-card-actions>
              <v-btn text @click="addBranchModal = false">Cancel</v-btn>
              <v-btn text @click="assignleads">Add</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <!-- end of dialog for assigning leads -->

        <!-- open leads model dialog box -->
        <VDialog v-model="openleadsmodel" style="width:40rem">
          <VCard>
            <VCardTitle>select language</VCardTitle>
            <VCardText>
              <VForm style="display:flex;justify-content:center;align-items:center">
               
                  <!-- <v-select
                  style="width:0rem"
                  v-model="selectedLanguage"
                  :items="languages"
                  item-text="language"
                  label="Select a language"
                ></v-select> -->
                <v-select
  v-model="selectedLanguage"
  :items="languages"
  item-text="name"
  item-value="id"
  label="Select a language"
></v-select>
                <!-- <VIcon @click="showAddLanguageDialog">mdi-plus</VIcon> -->
                <v-btn icon @click="addLanguageDialog=!addLanguageDialog">
                  <VIcon>mdi-plus</VIcon>
                </v-btn>
                
               
               
              
              </VForm>
            </VCardText>
    
            <VCardActions>
              <VBtn text @click="openleadsmodel = false">Cancel</VBtn>
              <VBtn text @click="openFileInput">Add</VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
        <!-- end of box -->
        <v-dialog v-model="addLanguageDialog" max-width="500">
          <v-card>
            <v-card-title>
              Add New Language
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="newLanguage" label="Language Name"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="addLanguage">Add</v-btn>
              <v-btn text @click="closeAddLanguageDialog">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
    
       <v-dialog v-model="assignedleadsbox" >
        <v-card class="dialog" >
          <v-card-title class="text-center"> Assigned Leads </v-card-title>
          <!-- <v-card-text>
           
          </v-card-text> -->
          <div class="">
            <v-row>
              <v-col>
                languages

              </v-col>
              <v-col>
                Count
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                languages

              </v-col>
              <v-col>
                Count
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                languages

              </v-col>
              <v-col>
                Count
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                languages

              </v-col>
              <v-col>
                Count
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                languages

              </v-col>
              <v-col>
                Count
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                languages

              </v-col>
              <v-col>
                Count
              </v-col>
            </v-row>
          </div>
  
         
        </v-card>
      </v-dialog>
       <!--  end of the code -->

  
  </div>

   
  </template>

  <script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  export default {
    name:"HomeCard",
    data () {
      return {
        addBranchModal: false,
        selectedBranch: null,
        openleadsmodel:false,
        assignedleadsbox:false,
        addLanguageDialog:false,
        languages:[],
        // language:'',
        noofleads:'',
        selectedLanguage: null,
        newLanguage: '',
        branches:[],
        skill: 40,
      knowledge: 53,
      power: 78,
      count:100,
      intelligence:25,
        interval: {},
        leadCount: 0,
        intcount:'0',
        notcount:'0',
        dnd:'0',
        busy:'0',
        todaycount:'0',
        followupcount:'0',
        assignedcount :'0'
        
      }
    },
    created()
    {
      this.language();
    this.fetchInterested()
    this.fetchnotInterested()
    this.fetchDnd()
    this.fetchbusy()
    this.fetchtodaycalls()
    this.FollowUp()
    

    },
    methods:
    {
    FollowUp() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Follow Up',
      empname:this.employeeName

    }
  })
  .then(response => {
    this.followupcount = response.data.admin;
  })
  .catch(error => {
    console.log(error);
  });
},
    
    fetchtodaycalls()
    {
      axios.get('http://127.0.0.1:5000/todaycalls')
  .then(response => {
    this.todaycount = response.data.count;
    console.log(this.todaycount)
  })
  .catch(error => {
    console.log(error);
  });
      
    },
    fetchInterested() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Interested',
      is_admin:'admin'
    }
  })
  .then(response => {
    this.intcount = response.data.admin;
  })
  .catch(error => {
    console.log(error);
  });
},
fetchnotInterested() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Not interested',
      is_admin:'admin'
    }
  })
  .then(response => {
    this.notcount = response.data.admin;
  })
  .catch(error => {
    console.log(error);
  });
},
fetchbusy() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Busy/Not Answered',
      is_admin:'admin'
    }
  })
  .then(response => {
    this.busy = response.data.admin;
   

  })
  .catch(error => {
    console.log(error);
  });
},
fetchDnd() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Dnd',
      is_admin:'admin'
    }
  })
  .then(response => {
    this.dnd = response.data.admin;
  })
  .catch(error => {
    console.log(error);
  });
},
  
    addLanguage() {
  if (this.newLanguage) {
    console.log(this.newLanguage)
    axios.post('http://127.0.0.1:5000/api/postlanguage', {
      language: this.newLanguage
      
    })
    .then(response => {
      console.log(response.data)
  
      this.languages.push(response.data.language);
      console.log(this.languages)

      
      this.newLanguage = ''
    })
    .catch(error => {
      console.log(error)
    })
  }
},

    openFileInput() {
      this.$refs.fileInput.click();
    },
    uploadFile() {
      const file = this.$refs.fileInput.files[0];
      const formData = new FormData();
      formData.append('file', file);
    

      axios.post('http://localhost:5000/add/leads', formData, { params: { language: this.selectedLanguage } }).then(response => {
  console.log(response.data.message)
  const num_rows = response.data.num_rows;
   Swal.fire({
        icon: 'success',
        title: `${num_rows}Leads uploaded successfully`,
        // text: `Number of leads: ${num_rows}`,
        showConfirmButton: false,
        timer: 1500
      })
}).catch(error => {
  console.log(error)
})

    },

    assignleads()
        {
          console.log(this.noofleads);
          console.log(this.selectedBranch)
        axios.post('http://127.0.0.1:5000/api/leads/assign', {
          count:this.noofleads,
  branch_name: this.selectedBranch
  

})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.log(error);
});
        },
        language() {
  axios.get("http://127.0.0.1:5000/api/getlanguage")
    .then(response => {
      this.languages.push(...response.data);
      console.log(this.languages);
    });
}

    },
    beforeUnmount () {
      clearInterval(this.interval)
    },
    mounted () {
      axios.get('http://127.0.0.1:5000/api/leads/count')
        .then(response => {
          this.leadCount = response.data.count;
        })
        .catch(error => {
          console.log(error);
        });
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0)
        }
        this.value += 0
      }, 1000)
    },
//     created()
//     {
// this.fetchbranchname()
//     },
    // getting branch names
  //  async created() {
      // try{
      //   const { data } = await axios.get("http://127.0.0.1:5000/apiget/branchename")
      //   // this.managers = data.emp_list
      //   this.branches=data.branch
        
      //   console.log(this.branches)
      //   }
      
      // catch (error) {
      //     console.error(error);
      //   }
    
  // },
  addBranch() {
      axios.post('http://127.0.0.1:5000/apipost/branches', {
        name: this.newBranchName,

        manager_name: this.selectedManager
      })
        .then(response => {
          this.branches.push(response.data)
          this.addBranchModal});
        },
//       
  }
</script>

<style  scoped>


.cards{
    color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align: center;
    box-shadow: 3px 3px 3px 3px #9999;
  
 
  
}
.cards:hover{
  background: pink;
   transform: scale(1);
}
.card
{
  height: 6rem;
  width: 3rem;
  background:white;
  display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    text-align: center;
    box-shadow: 3px 3px 3px 3px #9999;
}
.graph{
    margin-top: 10rem;
}
.v-progress-circular {
  margin: 1rem;
}
.dialog
{
 
  width: 30rem;
  height: 15rem;

  background: green;
  border-radius: 30px;
 

}

</style>
<template>
    <div class="">
        <AdminSidebar/>
        <MonthTarget/>
    </div>

  
</template>


<script>
import AdminSidebar from '../../components/Admin/AdminSidebar.vue'

import MonthTarget from '../../components/Admin/MonthTarget.vue'


export default {
    components:
    {
        AdminSidebar,MonthTarget
    }

}
</script>

<style>

</style>
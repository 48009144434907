
import { createRouter, createWebHistory } from 'vue-router'
import AdHome from '../pages/Admin/AdHome'
import LoginSample from '../pages/LoginSample.vue'
import BranchPage from '../pages/Admin/BranchPage.vue'
import ManagerHome from '../pages/Manager/ManagerHome.vue'
import BranchesDashboard from '../components/Admin/BranchesDashboard.vue'
import leadPage from '../components/Admin/leadPage.vue'
import AssignLeads from '../components/Admin/AssignLeads.vue'
import Home from '../views/HomeView.vue'
import Adminempl from '../components/Admin/EmployeeAdmin.vue'
import ManEmp from '../components/Manager/managercomponents/ManEmp.vue'
import adminintl from '../components/Admin/InterestedLeads.vue'

import Targetpage from '../pages/Admin/AdTarget.vue'

import AdPayment from '../pages/Admin/AdPaymen.vue'
import Adsplc from '../pages/Admin/AdSplc.vue'
import AdLeadRequest from '../pages/Admin/AdLeadRequest.vue'
import Adminlead from '../components/Admin/leadPage.vue'
import accounts from '../components/Admin/AdminAccounts.vue'

//manager imports
import LeadsView from '../pages/Manager/LeadsView.vue'
import Payment from '../pages/Manager/PaymentView.vue'
import RequestLead from '../pages/Manager/ReqleadsView.vue'
import MgHome from '../pages/Manager/HomeView.vue'
import Target from '../pages/Manager/TargetView.vue'
import splclient from '../components/Manager/SplClients.vue'
import mngemp from '../pages/Manager/EmpView.vue'
// end of manager imports 
// Employee imports 
import EmpHome from '../pages/Employee/EmpHome.vue'
import EmpLeadRequest from '../components/Employee/EmpReqLead.vue'
import EmpTarget from '../pages/Employee/EmptargetView.vue'
import Empleads from '../pages/Employee/EmpLeads.vue'
import empl from '../components/Employee/EmpLeads.vue'
import Notinterestedleads from '../components/Employee/NotInterestedLead.vue'
import interestedleads from '../components/Employee/InterestedLeads.vue'
import Empsplc from '../components/Employee/EmpspclClient.vue'
import EmpPayment from '../components/Employee/EmpPayment.vue'
import EmpnewPayment from '../components/Employee/NewPayment.vue'
import futurepay from '../components/Employee/FutPayment.vue'
import empchat from '../components/Employee/EmpMsg.vue'
import Dnd from '../components/Employee/DndLeads.vue'
import followup from '../components/Employee/LeadFollowup.vue'
import busy from '../components/Employee/BusyLeads.vue'
import partial from '../components/Employee/PartialPayments.vue'
// employee imports
// import axios from 'axios'

const routes = [
 
  {
    path: '/',
    name: 'home',
    component: LoginSample
  },
  {
    path: '/interest',
    name: 'adminintl',
    component: adminintl
  },
  {
    path: '/hv',
    name: 'hv',
    component: Home
  },
  {
    path: '/lp',
    name: 'lp',
    component: leadPage
  },
  {
    path: '/assign',
    name: 'assign',
    component: AssignLeads
  },
  {
    path: '/AdTarget',
    name: 'monthlytarget',
    component:Targetpage
  },
  {
    path: '/Adminemp',
    name: 'adminemp',
    component:Adminempl
  },
  {
    path: '/Adpay',
    name: 'adpay',
    component: AdPayment
  },
  {
    path: '/Adsplc',
    name: 'adsplc',
    component: Adsplc
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: accounts
  },
  {
    path: '/Adleads',
    name: 'adleads',
    component: Adminlead
  },
  {
    path: '/AdLeadRequest',
    name: 'AdLeadRequest',
    component: AdLeadRequest
  },
  {
    path: '/AdHome',
    name: 'adhome',
    component: AdHome,
    meta: {
      requiresAuth: true,
      role: 'admin'
    } // Add meta field to indicate that this route requires authentication
  },
  {
    path: '/Branch',
    name: 'branch',
    component: BranchPage,
    meta: { requiresAuth: true } // Add meta field to indicate that this route requires authentication
  },
  {
    path: '/MgHome/',
    name: 'mghome',
    component: ManagerHome,
    props: true,
    meta: {
      requiresAuth: true,
      role: 'manager'
    }// Add meta field to indicate that this route requires authentication
  },
  // {
  //   path: '/Ademp',
  //   name: 'Ademp',
  //   component: Adminemp,
  //   meta: { requiresAuth: true } // Add meta field to indicate that this route requires authentication
  // },
  {
    path: '/branch/:branchId',
    name: 'BranchesDashboard',
    component:BranchesDashboard,
    meta: { requiresAuth: true } // Add meta field to indicate that this route requires authentication
  },
  {
    path: '/ManEmp/:branchName/:manager',
    name: 'manemp',
    component:ManEmp,
    props: true,
    // meta: { requiresAuth: true } // Add meta field to indicate that this route requires authentication
  },
  // {
  //   path: '/Adminemp',
  //   name: 'Adminemp',
  //   component:Adminemp,
  //   // meta: { requiresAuth: true } // Add meta field to indicate that this route requires authentication
  // },

  // manager componenets routing
  {
    path: '/ML/:branchName/:manager',
    name: 'LeadsV',
    component:LeadsView,
    props: true,
    // meta: { requiresAuth: true } // Add meta field to indicate that this route requires authentication
  }
,
{
  path: '/Payment/:branchName/:manager',
  name: 'payment',
  component:Payment,
  props: true,
},
{
  path: '/RequestLead/:branchName/:manager',
  name: 'leadrequest',
  component:RequestLead,
  props: true,
},
{
  path: '/Target/:branchName/:manager',
  name: 'Target',
  component:Target,
  props: true,
},
{
  path:'/MgHome/:branchName/:manager',
  name: 'mghome',
  component:MgHome,
  props: true,
  meta: {
    requiresAuth: true,
    role: 'manager'
  }
},
{
  path: '/splc/:branchName/:manager',
  name: 'splclients',
  component:splclient,
  props: true,
},
{
  path: '/mngemp/:branchName/:manager',
  name: 'managemp',
  component:mngemp,
  props: true,
},
// end of manager routing
// emp routing
{
  path: '/emphome/:employeeName',
  name: 'emphome',
  component:EmpHome,
},
{
  path: '/emplead/:employeeName',
  name: 'emplead',
  component:Empleads,
},
{
  path: '/emptarget/:employeeName',
  name: 'emptarget',
  component:EmpTarget,
},
{
  path: '/empchat/:employeeName',
  name: 'empchat',
  component:empchat,
},

{
  path: '/el/:employeeName',
  name: 'el',
  component:empl,
},
{
  path: '/Intlead/:employeeName',
  name: 'intlead',
  component:interestedleads,
},
{
  path: '/NotIntlead/:employeeName',
  name: 'NOtintlead',
  component:Notinterestedleads,
},
{
  path: '/Dnd/:employeeName',
  name: 'Dnd',
  component:Dnd,
},
{
  path: '/busy/:employeeName',
  name: 'busy',
  component:busy,
},
{
  path: '/Followup/:employeeName',
  name: 'followup',
  component:followup,
},
{
  path: '/Leadrequest/:employeeName',
  name: 'leadrequest',
  component:EmpLeadRequest,
},
{
  path: '/empspcl/:employeeName',
  name: 'emplspcl',
  component:Empsplc,
},
{
  path: '/Emppay/:employeeName',
  name: 'emppay',
  component:EmpPayment,
},
{
  path: '/newpayment/:employeeName',
  name: 'empnewpay',
  component:EmpnewPayment,
},
{
  path: '/futurepay/:employeeName',
  name: 'futurepay',
  component:futurepay,
},
{
  path: '/partial/:employeeName',
  name: 'partial',
  component:partial,
},



//  end of emp routing
// {
//   path: '/MgHome/:branchName',
//   name: 'mghome',
//   component: ManagerHome,
//   meta: {
//     requiresAuth: true,
//     role: 'manager'
//   }// Add meta field to indicate that this route requires authentication
// },
  // end of manager routing
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Add navigation guard to check if the user is logged in before allowing access to the route
// router.beforeEach((to, from, next) => {
//   const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'
//   const userRole = localStorage.getItem('userRole')
//   const token = localStorage.getItem('token')

//   if (to.meta.requiresAuth && !isLoggedIn) {
//     next('/') // Redirect to login page if the user is not logged in
//   } else if (to.meta.requiresAdminAuth && isLoggedIn && userRole !== 'admin') {
//     next('/') // Redirect to login page if the user is not an admin
//   } else if (to.meta.requiresManagerAuth && isLoggedIn && userRole !== 'manager') {
//     next('/') // Redirect to login page if the user is not a manager
//   } else if (isLoggedIn && token) {
//     // Set the JWT token as the default authorization header for axios
//     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
//     next() // Allow access to the route
//   } else {
//     next() // Allow access to the route
//   }
// })
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const userRole = localStorage.getItem('userRole');
  if (to.meta.requiresAuth) {
    if (!isLoggedIn) {
      next('/login') // Redirect to login page if the user is not logged in
    } else if (to.meta.role && to.meta.role !== userRole) {
      next('/') // Redirect to access denied page if the user is not authorized
    } else {
      next() // Allow access to the route
    }
  } else {
    next() // Allow access to the public route
  }
});



export default router

<template>
    <VContainer>
     <div class="buttons">
       <!-- <button class="back" @click="goback"><VIcon>mdi-arrow-left-bold</VIcon></button> -->
      <VBtn @click="openAddBranchModal" class="mx-aut">Add Branch</VBtn>
    
      <VBtn @click="addEmpModal=!addEmpModal">Add Employees</VBtn></div>
      <VRow class="mt-2">
        <VCol v-for="branch in branches" :key="branch">
          <VCard  color=" mt-5"
          class="mx-aut bcards"
          max-width="300">
          <div class="card-content ">
           <div class="">
            <VCardTitle>{{ branch.name }}</VCardTitle>
            <!-- <v-card-title>{{ branch.manager_id }}</v-card-title> -->
           
            <!-- <VCardSubtitle>Manager:{{ mname[branch] }} {{ managerNames[branch.manager_id]}}</VCardSubtitle> -->
           </div>
           <div class="text-center mt-5" >
            <VProgressCircular
              :rotate="360"
              :size="50"
              :width="10"
              :model-value="value"
              color="teal"
            >
              {{ value }}
            </VProgressCircular>
          </div> 
          </div>
            <VCardActions>
              <!-- <VBtn text @click="goToManagerDashboard(branch.manager_id)"> -->
                <VBtn text @click="goToManagerDashboard(branch.id)">
               View Branch Dashboard 
              </VBtn>
            </VCardActions>
            <RouterView></RouterView>
          </VCard>
        </VCol>
      </VRow>
  
      <VDialog v-model="addBranchModal">
        <VCard>
          <VCardTitle>Add Branch</VCardTitle>
          <VCardText>
            <VForm>
              <VTextField label="Branch Location" v-model="branchlocation" />
              <VTextField label="Branch Name" v-model="newBranchName" />
             
            </VForm>
          </VCardText>
  
          <VCardActions>
            <VBtn text @click="addBranchModal = false">Cancel</VBtn>
            <VBtn text @click="addBranch">Add</VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
      <!-- employees addong model -->
      <VDialog v-model="addEmpModal">
        <VCard>
          <VCardTitle>Add Employees</VCardTitle>
          <VCardText>
            <VForm>
              <VTextField label="Employee Name" v-model="empname" />
              <VTextField label="Employee email" v-model="empemail" />
              <VTextField label="Employee password" v-model="emppassword" />
              <!-- <VTextField label="Employee branch" v-model="newEmployee" /> -->
              <VSelect
              label="Assign to branch"
              required
              v-model="selectedBranch"
              :items="branchname"
              item-text="name"
              item-value="id"
            />
            <!-- <v-select
                  label="branches"
                  v-model="selectedBranch"
                  :items="branchname"
                  item-text="name"
                  item-value="id"
                /> -->
             
            </VForm>
          </VCardText>
  
          <VCardActions>
            <VBtn text @click="addEmpModal = false">Cancel</VBtn>
            <VBtn text @click="addemp">Add</VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
      <!-- employess adding model ends -->
      <!-- <AssignLeads/> -->
    </VContainer>
    
  </template>
  
  <script>
  // import AssignLeads from './Dashboard/AssignLeads.vue'
  import axios from 'axios'
  export default {
    components:
    
  {
    // AssignLeads,
  },
    data() {
      return {
        addBranchModal: false,
        addEmpModal:false,
        newBranchName: '',
        branchlocation:'',
        empname:'',
        empemail:'',
        emppassword:'',
        selectedManager: null,
        selectedBranch:null,
        branches: [],
        branchname:[],
        managers: [ ],
        mname:[],
        managerNames:[],
        interval: {},
          value: 50,
      }
    },
  //   mounted() {
  //   this.fetchManagers().then(() => {
  //     console.log(this.managers);
  //   });
  // },
    created() {
      this.fetchBranches()
      this.fetchManagers()
      this.fetchbranchname()
    },
   
    methods: {
      goToManagerDashboard(branchId) {
        this.$router.push({ name: "BranchesDashboard", params: { branchId } })
      },
      goback()
        {
            this.$router.go(-1)
        },
      // async fetchBranches() {
      //   try {
      //     const response = await axios.get('http://127.0.0.1:5000/apiget/branches');
      //     this.branches = response.data;
      //     console.log(this.branches)
      //     this.branches.forEach(branch => {
      //       this.fetchManagerName(branch.manager_id);
      //     });
      //   } catch (error) {
      //     console.error(error);
      //   }
      // },
      async fetchBranches() {
        try {
          const response = await axios.get('http://127.0.0.1:5000/apiget/branches');
          this.branches = response.data;
          console.log(this.branches)
         
        } catch (error) {
          console.error(error);
        }
      },
      async fetchManagerName(m_id) {
        try {
          const response = await axios.get(`http://127.0.0.1:5000/managers/${m_id}`);
          this.managerNames[m_id] = response.data.name;
          console.log(this.managerNames[m_id])
        } catch (error) {
          console.error(error);
        }
      },
      async fetchbranchname()
      {
        try{
        const { data } = await axios.get("http://127.0.0.1:5000/apiget/branchename")
        // this.managers = data.emp_list
        this.branchname=data.branch
        
        console.log(this.branchname)
        }
      
      catch (error) {
          console.error(error);
        }

      },
    
  
     
      async fetchManagers() {
        try{
        const { data } = await axios.get('http://127.0.0.1:5000/managers')
        // this.managers = data.emp_list
        this.managers=data.employees
        
        console.log(this.managers)
        }
      
      catch (error) {
          console.error(error);
        }},
      openAddBranchModal() {
        this.addBranchModal = true
      }
    ,
    
      addBranch() {
        axios.post('http://127.0.0.1:5000/apipost/branches', {
          location:this.branchlocation,
          name: this.newBranchName,
  
         
        })
          .then(response => {
            this.branches.push(response.data)
            this.addBranchModal});
          },
          addemp() {
        axios.post('http://127.0.0.1:5000/insertemp', {
          name:this.empname,
          email:this.empemail,
          password:this.emppassword,
          branch:this.selectedBranch
        })
          .then(response => {
            // this.branches.push(response.data)
            // this.addBranchModal
            console.log(response.data)
          }
            );
          },

        }
      }
  
        
    </script>
    <style scoped>
    .card-content
    {
      display: flex;
      justify-content: space-around;
     
      
    }
    .bcards
    {
      
    box-shadow: 3px 3px 10px 3px #9999;
    border-radius: 5px;

    }
    .bcards:hover{
      background: linear-gradient(to left, rgb(244, 244, 244),rgb(243, 241, 242));
    }
    .buttons
    {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 30rem;
      
    }
    .back
    {
      background:white;
      color:black;
      font-size: 1rem;
      border-radius: 30px;
      height: 3rem;
      width: 3rem;
      box-shadow: 3px 3px 3px 3px #9999 ;
    }
    </style>
  
  
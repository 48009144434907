<template>
 <div class=""> <EmpSidebarVue />
  <v-container>
    <v-card-title>
      <span class="text-h5">Leads </span>
    </v-card-title>
    <v-row class="tota">
      <v-row class="tot">
        <v-col cols="12" sm="4">
          <v-card class="mx-auto h" width="280" :to="'/emplead/'+employeeName">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-account"
              color="pink"
            ></v-icon>
            <v-card-text>Total Leads </v-card-text>
            <v-card-text class="t"> {{ assignedcount- todaycount  }} </v-card-text>
         
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card :to="'/Intlead/'+employeeName" class="mx-auto h" width="270">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-thumb-up"
              color="green"
            ></v-icon>
            <v-card-text>Intrested Leads </v-card-text>
            <v-card-text class="t"> {{ intcount}} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card class="mx-auto h" width="270" :to="'/NotIntlead/'+employeeName">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-thumb-down"
              color="red"
            ></v-icon>
            <v-card-text>Not Intrested/ Wrong Number </v-card-text>
            <v-card-text class="t">{{ notcount }}</v-card-text>
          </v-card>
        </v-col>
     
      </v-row>
      <v-row class="tot mt-10">
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="270" to="/calls">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-phone"
              color="blue"
            ></v-icon>
            <v-card-text>Today Calls</v-card-text>
            <v-card-text class="t">{{ todaycount }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250" :to="'/followup/'+employeeName">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-account-group"
              color="orange"
            ></v-icon>
            <v-card-text>Follow Ups</v-card-text>
            <v-card-text class="t"> {{ followupcount }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="270" :to="'/Dnd/'+employeeName">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-cancel"
              color="pink"
            ></v-icon>
            <v-card-text>DND</v-card-text>
            <v-card-text class="t"> {{ dnd }} </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card class="mx-auto h" width="250"  :to="'/busy/'+employeeName">
            <v-icon
              class="ma-2"
              variant="text"
              icon="mdi-minus-circle"
              color="blue"
            ></v-icon>
            <v-card-text> Busy/Not Pick Up/Switch Off</v-card-text>
            <v-card-text class="t"> {{ busy }} </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container></div>
</template>
<script>
import axios from 'axios';
import EmpSidebarVue from './EmpSidebar.vue';
export default {
  components: {
   EmpSidebarVue
  },
  data() {
      return {
        employeeName:'',
        intcount:'0',
        notcount:'0',
        dnd:'0',
        busy:'0',
        todaycount:'0',
        followupcount:'0',
        assignedcount :'0'
        
      };
    },
    created()
  {
    this.employeeName = this.$route.params.employeeName;
   
    console.log(this.employeeName);
    this.fetchInterested()
    this.fetchnotInterested()
    this.fetchDnd()
    this.fetchbusy()
    this.fetchtodaycalls()
    this.FollowUp()
    this.assigncount()
  },
  methods:
  {
    assigncount() {
  axios.get('http://127.0.0.1:5000/assignesleads', {
    params: {
      // status:this.employeeName,
      emp_name:this.employeeName
    }
  })
  .then(response => {
    this.assignedcount = response.data.count[0];
    console.log(this.assignedcount)
  })
  .catch(error => {
    console.log(error);
  });
},
    FollowUp() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Follow Up',
      empname:this.employeeName

    }
  })
  .then(response => {
    this.followupcount = response.data.emp;
    alert(this.followupcount)
  })

  .catch(error => {
    console.log(error);
  });
},
    
fetchtodaycalls() {
  axios.get('http://127.0.0.1:5000/todaycalls', {
    params: {
      name: this.employeeName
    }
  })
  .then(response => {
    this.todaycount = response.data.count;
    console.log(this.todaycount);
  })
  .catch(error => {
    console.log(error);
  });


      
    },
    fetchInterested() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Interested',
      empname:this.employeeName
    }
  })
  .then(response => {
    this.intcount = response.data.emp;
  })
  .catch(error => {
    console.log(error);
  });
},
fetchnotInterested() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Not interested',
      empname:this.employeeName
    }
  })
  .then(response => {
    this.notcount =response.data.emp;
  })
  .catch(error => {
    console.log(error);
  });
},
fetchbusy() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Busy/Not Answered',
      empname:this.employeeName
    }
  })
  .then(response => {
    this.busy =response.data.emp;
  })
  .catch(error => {
    console.log(error);
  });
},
fetchDnd() {
  axios.get('http://127.0.0.1:5000/leads_status', {
    params: {
      status: 'Dnd',
      empname:this.employeeName
    }
  })
  .then(response => {
    this.dnd = response.data.emp;
  })
  .catch(error => {
    console.log(error);
  });
}


  }
};
</script>
<style>
.tota {
  margin-top: 3%;
}

.h {
  text-align: center;
  /* height: 10rem; */
}
.t {
  font-size: 1.6rem;
}
</style>

<template>
  <div class=""><EmpSidebar />
    <v-container class="total">
      <v-sheet width="500" class="mx-auto">
        <v-card-text class="txt">Send Message</v-card-text>
        <v-form fast-fail @submit.prevent>
          <v-select
            v-model="select1"
            :items="msgopt"
            label="Send message to"
            required
          ></v-select>
  
          <v-text-field mt="5" v-model="firstName" label="Subject"></v-text-field>
          <!-- <v-textarea label="Label"></v-textarea> -->
          <v-container fluid>
            <v-textarea
              clearable
              clear-icon="mdi-close-circle"
              label="Message"
              model-value=""
            ></v-textarea>
          </v-container>
  
          <!-- <v-text-field v-model="firstName" label="Amount"></v-text-field> -->
          <div>
            <v-btn type="submit" color="red" class="mt-2 bt">Submit</v-btn>
            <v-btn type="submit" color="primary" to="/" class="mt-2 bt"
              >Back</v-btn
            >
          </div>
        </v-form>
      </v-sheet>
    </v-container></div>
</template>
<script>
import EmpSidebar from './EmpSidebar.vue';
export default {
  components: {
    EmpSidebar,
  },
  name: "ReqLead",
  data: () => ({
    firstName: "",
    msgopt: ["Admin", "manager"],
    firstNameRules: [
      (value) => {
        if (value?.length > 1) return true;

        return "Leads must be at least 10 ";
      },
    ],
  }),
};
</script>
<style>
.total {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.mx-auto {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.txt {
  /* height: 2rem; */
  text-align: center;
  font-size: 1.2rem;
  background-color: #def1fe;
}
.bt {
  margin-left: 1rem;
}
</style>
